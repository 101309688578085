import { THROW_ERROR } from '../Error/actions';
import { updateObject } from '../helpers/updateObject';
import {GET_CLIENT_DELAYED_BOOST_INFO, GET_CLIENT_TARIFF_INFO, UPDATE_CLIENT_NAME, UPDATE_USER_PHONE} from './actions';


export type ClientTariffInfo = {
    currentTariffName: string;
    nextTariffName: string | undefined;
    nextPaymentDate: Date | undefined;
}


export type ClientDelayedBoostInfo = {
    delayedBoostInfo: DelayedBoost | undefined;
}

export type DelayedBoost = {
    activationDate: Date;
    originalAmount: number;
    plannedForDisburseAmount: number;
    boostAmount: number;
};
export type ClientState = {
    isSaved: false
    isPhoneUpdateLoading: boolean;
    IsNameUpdating: boolean;
    IsTariffInfoLoading: boolean;
    IsTariffInfoFetched: boolean;
    IsDelayedBoostInfoLoading: boolean;
    TariffInfo: ClientTariffInfo | undefined;
    DelayedBoostInfo: ClientDelayedBoostInfo | undefined;
}



const initialState: ClientState = {
    isPhoneUpdateLoading: false,
    isSaved: false,
    IsNameUpdating: false,
    IsTariffInfoLoading: false,
    IsTariffInfoFetched: false,
    TariffInfo: undefined,
    DelayedBoostInfo: undefined,
    IsDelayedBoostInfoLoading: false
    
}


export const reducer = (state: ClientState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isPhoneUpdateLoading: false, IsNameUpdating: false, isSaved: false });
        case UPDATE_USER_PHONE.REQUEST:
            return updateObject(state, { isPhoneUpdateLoading: true });
        case UPDATE_USER_PHONE.SUCCESS:
            return updateObject(state, { isPhoneUpdateLoading: false, isSaved: true });
        case UPDATE_CLIENT_NAME.REQUEST:
            return updateObject(state, { IsNameUpdating: true });
        case UPDATE_CLIENT_NAME.SUCCESS:
            return updateObject(state, { IsNameUpdating: false, isSaved: true });
        case GET_CLIENT_TARIFF_INFO.REQUEST:
            return updateObject(state, { IsTariffInfoLoading: true, IsTariffInfoFetched: false });
        case GET_CLIENT_TARIFF_INFO.SUCCESS:
            return updateObject(state, { IsTariffInfoLoading: false, IsTariffInfoFetched: true, TariffInfo: action.tariffInfo });
        case GET_CLIENT_DELAYED_BOOST_INFO.REQUEST:
            return updateObject(state, { IsDelayedBoostInfoLoading: true });
        case GET_CLIENT_DELAYED_BOOST_INFO.SUCCESS:
            return updateObject(state, { IsDelayedBoostInfoLoading: false, DelayedBoostInfo: action.delayedBoostInfo });
            
            

        default:
            return state;
    }
}