import { ExternalCardHistoryItem, TransferErrorLog, UserUpdate } from './reducer';
import sendRequest from '../../core/apiHelper';
import { throwError } from '../../Error/actions';
import { PaginatedList } from '../../reducers';

// get user updates
export enum GET_USER_UPDATES {
    REQUEST = 'GET_USER_UPDATES_REQUEST',
    SUCCESS = 'GET_USER_UPDATES_SUCCESS',
}
export const getUserUpdatesRequest = () => {
    return {
        type: GET_USER_UPDATES.REQUEST
    };
};

export const getUserUpdatesSuccess = (userUpdates: UserUpdate[]) => {
    return {
        type: GET_USER_UPDATES.SUCCESS,
        userUpdates: userUpdates
    };
};

export const getUserUpdates = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getUserUpdatesRequest());
        sendRequest(`/activity-log/${clientId}/user-updates`)
            .then((response: any) => {
                dispatch(getUserUpdatesSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};

// get External Cards History
export enum GET_EXTERNAL_CARDS_HISTORY {
    REQUEST = 'GET_EXTERNAL_CARDS_HISTORY_REQUEST',
    SUCCESS = 'GET_EXTERNAL_CARDS_HISTORY_SUCCESS',
}
export const getExternalCardsHistoryRequest = () => {
    return {
        type: GET_EXTERNAL_CARDS_HISTORY.REQUEST
    };
};

export const getExternalCardsHistorySuccess = (externalCardsHistory: ExternalCardHistoryItem[]) => {
    return {
        type: GET_EXTERNAL_CARDS_HISTORY.SUCCESS,
        externalCardsHistory: externalCardsHistory
    };
};

export const getExternalCardsHistory = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getExternalCardsHistoryRequest());
        sendRequest(`/activity-log/${clientId}/external-cards`)
            .then((response: any) => {
                dispatch(getExternalCardsHistorySuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};

// Get Transfer Error Logs
export enum GET_TRANSFER_ERROR_LOGS {
    REQUEST = 'GET_TRANSFER_ERROR_LOGS_REQUEST',
    SUCCESS = 'GET_TRANSFER_ERROR_LOGS_SUCCESS',
}
export const getTransferErrorLogsRequest = () => {
    return {
        type: GET_TRANSFER_ERROR_LOGS.REQUEST
    };
};

export const getTransferErrorLogsSuccess = (transferErrorLogsPaginatedList: PaginatedList<TransferErrorLog>) => {
    return {
        type: GET_TRANSFER_ERROR_LOGS.SUCCESS,
        transferErrorLogsPaginatedList: transferErrorLogsPaginatedList
    };
};

export const getTransferErrorLogs = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getTransferErrorLogsRequest());
        sendRequest(`/activity-log/${clientId}/transfer-errors`)
            .then((response: any) => {
                dispatch(getTransferErrorLogsSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};
