import {AdvancesHistoryEntry, ClientAdvancesHistory} from './reducer';
import sendRequest from '../../core/apiHelper';
import { throwError } from '../../Error/actions';
import { DAYS_TO_SEARCH_OVERDUE_ADVANCES, ACCEPTABLE_ADVANCE_OVERDUE_IN_DAYS } from "../../PageClients/constants";

// get client advances history
export enum GET_CLIENT_ADVANCES_HISTORY {
    REQUEST = 'GET_CLIENT_ADVANCES_HISTORY_REQUEST',
    SUCCESS = 'GET_CLIENT_ADVANCES_HISTORY_SUCCESS',
    ERROR = 'GET_CLIENT_ADVANCES_HISTORY_ERROR',
}
export const getClientAdvancesHistoryRequest = () => {
    return {
        type: GET_CLIENT_ADVANCES_HISTORY.REQUEST
    };
};

export const getClientAdvancesHistorySuccess = (clientAdvancesHistory: ClientAdvancesHistory) => {
    return {
        type: GET_CLIENT_ADVANCES_HISTORY.SUCCESS,
        clientAdvancesHistory: clientAdvancesHistory
    };
};

export const getClientAdvancesHistoryError = () => {
    return {
        type: GET_CLIENT_ADVANCES_HISTORY.ERROR
    };
};

export const getClientAdvancesHistory = (clientId: string) => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - DAYS_TO_SEARCH_OVERDUE_ADVANCES);
    return (dispatch: any) => {
        dispatch(getClientAdvancesHistoryRequest());
        sendRequest(`/clients/${clientId}/advances-history`)
            .then((response: any) => {
                const overdueAdvancesCount = response.advancesHistoryEntries.filter((entry: AdvancesHistoryEntry) => {
                   return (entry.dpd && entry.dpd > ACCEPTABLE_ADVANCE_OVERDUE_IN_DAYS) &&
                       (entry.disbursedAt && new Date(entry.disbursedAt) > startDate)
                });
                response.overdueAdvancesCount = overdueAdvancesCount.length;
                dispatch(getClientAdvancesHistorySuccess(response));
            })
            .catch((error: any) => {
                dispatch(getClientAdvancesHistoryError());
            });
    };
};
