import { AdminUser } from '../AdminUsers/reducer';
import { THROW_ERROR } from '../Error/actions';
import { updateObject } from '../helpers/updateObject';
import { CHANGE_PASSWORD, GET_USER_PROFILE } from './actions';

export type ProfileState = {
    userProfile?: AdminUser;
    isLoading: false,
    isSaved: false
}

const initialState: ProfileState = {
    userProfile: undefined,
    isLoading: false,
    isSaved: false
}

export const reducer = (state: ProfileState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false, isSaved: false });
        case GET_USER_PROFILE.REQUEST:
            return updateObject(state, { isLoading: true, userProfile: undefined });
        case GET_USER_PROFILE.SUCCESS:
            return updateObject(state, { isLoading: false, userProfile: action.userProfile });
        case CHANGE_PASSWORD.REQUEST:
            return updateObject(state, { isLoading: true, isSaved: false });
        case CHANGE_PASSWORD.SUCCESS:
            return updateObject(state, { isLoading: false, isSaved: true });

        default:
            return state;
    }
}