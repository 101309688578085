import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import BadgeIcon from '@mui/icons-material/Badge';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  AlternateEmail as AlternateEmailIcon,
  CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon,
  CircleOutlined as CircleOutlinedIcon,
  ErrorOutlineRounded as ErrorOutlineRoundedIcon,
  LocalPhoneRounded as LocalPhoneRoundedIcon,
  Pending as PendingIcon,
  Place as PlaceIcon,
} from "@mui/icons-material";
import {Box, Collapse, Link, Stack, Tooltip, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { useParams } from "react-router-dom";
import Block from "./ClientActions/Block";
import BlockMbanq from "./ClientActions/BlockMbanq";
import Unblock from "./ClientActions/Unblock";
import UnblockMbanq from "./ClientActions/UnblockMbanq";
import DeleteArgyleData from "./ClientActions/DeleteArgyleData";
import JSONPretty from "../core/JSONPretty";
import TransactionLimits from "./TransactionLimits/TransactionLimits";
import CloseAccount from './ClientActions/CloseAccount/CloseAccount';
import { AuthData } from "../Auth/reducer";
import { AppState } from "../reducers";
import { connect } from "react-redux";
import { hasAccessToBlockClients, hasAccessToBlockClientsInMBanq, hasAccessToCloseAccount, hasAccessToUpdateEmail, hasAccessToUpdateName, hasAccessToUpdatePhoneNumber, hasAccessToViewDataInTheClientCard } from "../Auth/accessLevelsHelper";
import IconButton from '@mui/material/IconButton';
import ClientDocumentsPopup from "../ClientDocuments/ClientDocumentsPopup";
import ActivityLog from "./ActivityLog/ActivityLog";
import './PageClient.css';
import { updateClientName, updateUserPhone, getClientTariffInfo, getClientDelayedBoostInfo } from "./actions";
import LoadingButton from '@mui/lab/LoadingButton';
import CustomAlert from "../core/Alert/Alert";
import { resetError } from "../Error/actions";
import OtherActionsTab from "./OtherTab/OtherActionsTab";
import ClientCashbackHistoryTable from "./Cashback/CashbackHistory/ClientCashbackHistoryTable";
import {ClientDelayedBoostInfo, ClientTariffInfo} from "./reducer";
import {debug} from "util";
import ClientAdvancesHistoryTable from "./Advances/AdvancesHistory/ClientAdvancesHistoryTable";
import {AdvanceAdminStatusEnum, AdvancesHistoryEntry, ClientAdvancesHistory} from "./Advances/reducer";
import {getClientAdvancesHistory} from "./Advances/actions";
import {ACCEPTABLE_ADVANCE_OVERDUE_IN_DAYS, DAYS_TO_SEARCH_OVERDUE_ADVANCES} from "../PageClients/constants";
import ClientDocVAttemptsTable from "./DocVerification/DocumentVerificationAttemptsTable";

export const CLIENT = gql`
  query Client($clientId: String!) {
    client(clientId: $clientId)
      @rest(type: "Client", path: "/clients/{args.clientId}") {
      firstName
      id
      lastName
      phoneNumber
      status
      email
      dateOfBirth
      identityType
      identityValue
      payAllocations
      blockingReason {
        id
        value
      }
      closingReason {
        id
        value
      }
      closedAt
      address {
        addressLine
        city
        state
        unit
        zipCode
      }
      limit {
        value
      }
      fixedLimit
      advancedLimit
      recentPayrolls
      currentAdvanceUsed
      rating
      payAllocations {
        status
        allocationType
        allocationValue
        argyleUserId
      }
      achTransfers
      kycStatus
    }
    onboardingDetails(clientId: $clientId)
      @rest(
        type: "Client"
        path: "/clients/{args.clientId}/onboarding-details"
      ) {
      address
      identity
      personalInformation
      proofOfIdentity
      stepsInfo
    }
  }
`;

export const MBANQ_DETAILS = gql`
  query Client($clientId: String!) {
    mbanqDetails(clientId: $clientId)
      @rest(
        type: "Client"
        path: "/clients/{args.clientId}/saving-account"
      ) {
      isBlocked,
      availableBalance
    }
  }
`;

const UPDATE_USER_ADDRESS = gql`
mutation UpdateAddress($clientId: ID!, $addressLine: String!, $unit: String!, $city: String!, $state: String!, $zipCode: String! ) {
  updateAddress(clientId: $clientId, input: { address: $addressLine, unit: $unit, city: $city, state: $state, zipCode: $zipCode })
    @rest(
      type: "Client"
      method: "PUT"
      path: "/clients/{args.clientId}/address"
    ) {
    NoResponse
  }
}
`;

const UPDATE_USER_EMAIL = gql`
mutation UpdateEmail($clientId: ID!, $newEmail: String!) {
  updateEmail(clientId: $clientId, input: { newEmail: $newEmail })
    @rest(
      type: "Client"
      method: "PUT"
      path: "/clients/{args.clientId}/email"
    ) {
    NoResponse
  }
}
`;

export const PAYROLL_KEYWORDS = gql`
  query PayrollKeywords {
    payrollKeywords
      @rest(
        type: "[String]",
        path: "/loan-settings/principal-keywords"
      )
  }
`;


const STEP_NAME_TO_DATA_KEY = {
  pendingAddress: "address",
  pendingSSN: "identity",
  pendingPersonalInformation: "personalInformation",
  proofOfIdentity: "proofOfIdentity",
} as any;

interface StateFromProps {
  authData?: AuthData;
  isPhoneUpdateLoading: boolean;
  IsNameUpdating: boolean;
  errorMessage: string;
  isError: boolean;
  tariffInfo?: ClientTariffInfo;
  isTariffInfoLoading: boolean;
  advancesHistory?: ClientAdvancesHistory
  isAdvancesInfoLoading: boolean;
  IsDelayedBoostInfoLoading: boolean;
  DelayedBoostInfo?: ClientDelayedBoostInfo;
}

interface DispatchFromProps{
  updateUserPhone: (clientId: string, newPhone: string) => void;
  updateClientName: (clientId: string, firstName?: string, lastName?: string) => void;
  getClientTariffInfo: (clientId: string) => void;
  getClientAdvancesHistory: (clientId: string) => void;
  getClientDelayedBoostInfo: (clientId: string) => void;
  resetError: () => void;
}

const PageClient = (props: StateFromProps & DispatchFromProps) => {

  const { clientId } = useParams();

  useEffect(() => {
    if (clientId) { 
      props.getClientTariffInfo(clientId);
      props.getClientAdvancesHistory(clientId);
      props.getClientDelayedBoostInfo(clientId);
    }
  }, [])

  const { loading, error, data } = useQuery(CLIENT, {
    variables: { clientId },
  });

  const mbanqData = useQuery(MBANQ_DETAILS, {
    variables: { clientId },
  });

  const [detailsTabIndex, setDetailsTabIndex] = useState('1');

  const [isClientDocumentsPopupOpen, setIsClientDocumentsPopupOpen] = useState<boolean>(false);

  const updateTabIndex = (_: any, newValue: any) => {
    setDetailsTabIndex(newValue);
  };


  const [addressToUpdate, setAddressToUpdate] = useState<any>({});
  const [phoneToUpdate, setPhoneToUpdate] = useState<any>("");
  const [emailToUpdate, setEmailToUpdate] = useState<any>("");
  const [firstNameToUpdate, setFirstNameToUpdate] = useState<any>("");
  const [lastNameToUpdate, setLastNameToUpdate] = useState<any>("");

  const { client, onboardingDetails } = data || {};

  useEffect(() => {
    if (client) {
      setAddressToUpdate({
        unit: client.address?.unit,
        addressLine: client.address?.addressLine,
        city: client.address?.city,
        state: client.address?.state,
        zipCode: client.address?.zipCode
      })

      setFirstNameToUpdate(client.firstName);
      setLastNameToUpdate(client.lastName);
      setPhoneToUpdate(client.phoneNumber);
      setEmailToUpdate(client.email);
    }
  }, [client]);

  const [updateAddress] = useMutation(UPDATE_USER_ADDRESS, {
    refetchQueries: [CLIENT],
    onError: (error) => {
      alert(error);
    },
  });

  const [updateEmail] = useMutation(UPDATE_USER_EMAIL, {
    refetchQueries: [CLIENT],
    onError: (error) => {
      alert(error);
    },
  });

  const payrollKeywordsResponse = useQuery(PAYROLL_KEYWORDS)
  
  if (loading || props.isTariffInfoLoading || props.isAdvancesInfoLoading || props.IsDelayedBoostInfoLoading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let payrollsList: any = []
  if (client && payrollKeywordsResponse && client.achTransfers) {
    let keywords = payrollKeywordsResponse?.data?.payrollKeywords?.map((kw: any) => kw.toUpperCase()) ?? []
    payrollsList.splice(0, payrollsList.length);
    client.achTransfers.forEach((p: any) => {
      const result = ({ ...p });
      result.amount = Math.round(p.amount * 100) / 100;
      result.reference = p.reference.toUpperCase();
      result.isCounted = (new Date() as any) - (14 as any) > (new Date(p.createdAt) as any);
      result.createdAt = new Date(p.createdAt).toDateString();
      result.isPayroll = keywords.some((x: any) => result.reference.indexOf(x) !== -1);
      result.formattedReference = p.reference.toUpperCase();
      keywords.forEach((x: any) => result.formattedReference = result.formattedReference.replace(x, `<span style="background: green;">${x}</span>`));
      payrollsList.push(result);
    })
  };

  const addressLine = [
    client.address?.unit,
    client.address?.addressLine,
    client.address?.city,
    client.address?.state,
    client.address?.zipCode,
  ]
    .filter((value) => value)
    .join(", ");

  const { mbanqDetails } = (mbanqData || {}).data || {}

  const StackIdentityInfo = (
    <Stack spacing={1}>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h2" gutterBottom>
            Identity Information
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <IconButton
            style={{
              marginTop: -20,
              marginLeft: -25
            }}
            onClick={() => setIsClientDocumentsPopupOpen(true)}
          >

            <BadgeIcon
              fontSize="large"
              color="primary"
            />

          </IconButton>

          <ClientDocumentsPopup
            isOpen={isClientDocumentsPopupOpen}
            handleClose={() => setIsClientDocumentsPopupOpen(false)}
          />

        </Grid>
      </Grid>

      <Box sx={{ pl: 2 }}>
        <DefinitionPairLayout
          dt="Date of Birth"
          dd={
            client.dateOfBirth && (
              <FormattedDate value={client.dateOfBirth} dateStyle="medium" />
            )
          }
        />
        <DefinitionPairLayout
          dt="ID"
          dd={
            <>
              {client.identityType}&nbsp;{client.identityValue}
            </>
          }
        />
        <DefinitionPairLayout
            dt="KYC Status"
            dd={
              <>
                {client.kycStatus ? client.kycStatus : "Not verified"}
              </>
            }
        />
      </Box>
    </Stack>
  );
  
  const currentAdvance : AdvancesHistoryEntry | undefined = props.advancesHistory?.advancesHistoryEntries?.sort((a, b) =>
      b.loanId - a.loanId).filter((el) => el.closedDate === null)[0];
  
  // TODO: TBD StackPayAllocations
  const StackStatistics = (
    <Stack spacing={1}>
      <Typography variant="h2" gutterBottom>
        Wallet And Offers
      </Typography>
      <Box sx={{ pl: 2 }}>
        <DefinitionPairLayout dt="Available Balance" dd={mbanqDetails ? mbanqDetails.availableBalance : '-'} />
        <DefinitionPairLayout dt="Current Membership" dd={props.tariffInfo?.currentTariffName} />
        <DefinitionPairLayout dt="Upcoming Membership" dd={props.tariffInfo?.nextTariffName} />
        <DefinitionPairLayout dt="Next Charge Date" dd={props.tariffInfo?.nextPaymentDate?.toDateString()} />
        <br/>
        <DefinitionPairLayout dt="Fixed Limit" dd={client.fixedLimit} />
        <DefinitionPairLayout dt="Advanced Limit" dd={client.advancedLimit} />
        <DefinitionPairLayout dt="2 Week Payrolls" dd={client.recentPayrolls} />
        <DefinitionPairLayoutWithTooltip dt="Overdue Advances Count" dd={props.advancesHistory?.overdueAdvancesCount}
                                         tp={`Advances > ${ACCEPTABLE_ADVANCE_OVERDUE_IN_DAYS} days late in last ${DAYS_TO_SEARCH_OVERDUE_ADVANCES} days`} />
        {props.DelayedBoostInfo?.delayedBoostInfo ?
            <>
              <DefinitionPairLayout dt="Delayed Advance"
                                    dd={props.DelayedBoostInfo?.delayedBoostInfo.plannedForDisburseAmount}/>
              <DefinitionPairLayout dt="Delayed Boost Amount"
                                    dd={props.DelayedBoostInfo?.delayedBoostInfo.boostAmount}/>
              <DefinitionPairLayout dt="Original Amount"
                                    dd={props.DelayedBoostInfo?.delayedBoostInfo.originalAmount}/>
              <DefinitionPairLayout dt="Planned date of disbursement"
                                    dd={props.DelayedBoostInfo?.delayedBoostInfo.activationDate ? 
                                        new Date(props.DelayedBoostInfo?.delayedBoostInfo.activationDate).toDateString() : undefined}/>
            </>
            : currentAdvance ? 
            <>
              <DefinitionPairLayout dt="Advance Used" dd={currentAdvance?.amountUsed}/>
              <DefinitionPairLayout dt="Boost Used" dd={currentAdvance?.boost}/>
              <DefinitionPairLayout dt="Original Amount" dd={currentAdvance?.originalAmount}/>
              <DefinitionPairLayout dt="Disbursed At" dd={currentAdvance?.disbursedAt ? new Date(currentAdvance.disbursedAt).toDateString() : undefined}/>
            </> : null
        }
      </Box>
    </Stack>
  );

  const StackPayAllocations = (
    <Stack spacing={1}>
      <Box sx={{ pl: 2 }}>
        <Typography variant="h2" gutterBottom>
          Pay Allocations
        </Typography>
        <Stack direction="column" sx={{ pl: 2 }} spacing={2}>
          {client.payAllocations?.map((pa: any, index: any) => (
            <Box key={index}>
              {pa?.argyleUserId != null && <DefinitionPairLayout dt="Argyle User Id" dd={pa?.argyleUserId} />}

              {pa?.status != null && <DefinitionPairLayout dt="Status" dd={pa?.status} />}

              {(pa?.allocationType != null && pa?.allocationValue != null)
                && <DefinitionPairLayout
                  dt={pa?.allocationType}
                  dd={pa?.allocationValue}
                />}

            </Box>
          ))}
        </Stack>
        <br />

        {
          props.authData && hasAccessToBlockClients(props.authData)
            ? <DeleteArgyleData />
            : null
        }

      </Box>
    </Stack>
  );

  const StackContacts = (
    <Stack spacing={1}>
      <Typography variant="h2" gutterBottom>
        Contacts
      </Typography>
      <Box sx={{ pl: 2 }}>
        <Stack direction="row" spacing={1}>
          <LocalPhoneRoundedIcon color="action" />
          <Link href={`tel:${client.phoneNumber}`}>{client.phoneNumber}</Link>
        </Stack>
        <Stack direction="row" spacing={1}>
          <AlternateEmailIcon color="action" />
          <Link href={`mailto:${client.email}`}>{client.email}</Link>
        </Stack>
        <Stack direction="row" spacing={1}>
          <PlaceIcon color="action" />
          <Link
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              addressLine
            )}`}
          >
            {addressLine}
          </Link>
        </Stack>
      </Box>
    </Stack>
  );

  const StackOnboardingDetails = (
    <Stack spacing={1}>
      <Timeline sx={{ mt: 0 }}>
        {onboardingDetails.stepsInfo.map((stepInfo: any) => {
          return (
            <TimelineItemOnboarding
              key={stepInfo.step}
              {...stepInfo}
              clientStatus={client.status}
              stepData={onboardingDetails[STEP_NAME_TO_DATA_KEY[stepInfo.step]]}
            />
          );
        })}
      </Timeline>
    </Stack>
  );

  const StackUserEditor = (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', m: 2 },
      }}
      noValidate
      autoComplete="off"
    >
      {
        props.authData && hasAccessToUpdatePhoneNumber(props.authData)
          ? <Box sx={{ borderRadius: 5, border: 1, color: '#DDDDDD', padding: 2, margin: 3 }}>
            <TextField
              label="New Phone"
              id="standard-size-normal"
              value={phoneToUpdate}
              variant="standard"
              onChange={(e) => setPhoneToUpdate(e.target.value)}

            />
            <LoadingButton 
              loading={props.isPhoneUpdateLoading} 
              variant="contained" 
              size="large" 
              sx={{ top: 30 }} 
              onClick={() => { props.updateUserPhone(clientId as string, phoneToUpdate); setPhoneToUpdate(""); }} 
            >
              Update phone
            </LoadingButton>
          </Box>
          : null
      }

      <Box sx={{ borderRadius: 5, border: 1, color: '#DDDDDD', padding: 2, margin: 3 }}>
        <TextField
          label="Address Line"
          id="standard-size-normal"
          value={addressToUpdate.addressLine}
          variant="standard"
          onChange={(e) => setAddressToUpdate({ ...addressToUpdate, addressLine: e.target.value })}
        />
        <TextField
          label="Unit"
          id="standard-size-normal"
          value={addressToUpdate.unit}
          variant="standard"
          onChange={(e) => setAddressToUpdate({ ...addressToUpdate, unit: e.target.value })}
        />
        <TextField
          label="City"
          id="standard-size-normal"
          value={addressToUpdate.city}
          variant="standard"
          onChange={(e) => setAddressToUpdate({ ...addressToUpdate, city: e.target.value })}
        />
        <TextField
          label="State"
          id="standard-size-normal"
          value={addressToUpdate.state}
          variant="standard"
          onChange={(e) => setAddressToUpdate({ ...addressToUpdate, state: e.target.value })}
        />
        <TextField
          label="Zipcode"
          id="standard-size-normal"
          value={addressToUpdate.zipCode}
          variant="standard"
          onChange={(e) => setAddressToUpdate({ ...addressToUpdate, zipCode: e.target.value })}
        />

        {
          props.authData && hasAccessToUpdatePhoneNumber(props.authData)
            ? <Button variant="contained" size="large" sx={{ top: 30 }}
              onClick={() => { updateAddress({ variables: { clientId: clientId, ...addressToUpdate } }) }}
            >
              Update address
            </Button>
            : null
        }

      </Box>

      {
        props.authData && hasAccessToUpdateEmail(props.authData)
          ?
          <Box sx={{ borderRadius: 5, border: 1, color: '#DDDDDD', padding: 2, margin: 3 }}>
            <TextField
              label="New Email"
              id="standard-size-normal"
              value={emailToUpdate}
              variant="standard"
              onChange={(e) => setEmailToUpdate(e.target.value)}
            />

            <Button variant="contained" size="large" sx={{ top: 30 }}
              onClick={() => { updateEmail({ variables: { clientId: clientId, newEmail: emailToUpdate } }); setEmailToUpdate(""); }}
            >
              Update email
            </Button>
          </Box>

          : null
      }

{
        props.authData && hasAccessToUpdateName(props.authData)
          ? <Box sx={{ borderRadius: 5, border: 1, color: '#DDDDDD', padding: 2, margin: 3 }}>
            <TextField
              label="First Name"
              id="standard-size-normal"
              value={firstNameToUpdate}
              variant="standard"
              onChange={(e) => setFirstNameToUpdate(e.target.value)}

            />
             <TextField
              label="Last Name"
              id="standard-size-normal"
              value={lastNameToUpdate}
              variant="standard"
              onChange={(e) => setLastNameToUpdate(e.target.value)}

            />
            <LoadingButton 
              loading={props.IsNameUpdating} 
              variant="contained" 
              size="large" 
              sx={{ top: 30 }} 
              onClick={() => { props.updateClientName(clientId as string, firstNameToUpdate?.trim(), lastNameToUpdate?.trim());}} 
            >
              Update Client Name
            </LoadingButton>
          </Box>
          : null
      }

    </Box>
  );

  if (!props.authData) {
    return <></>;
  }
  else {
    if (!hasAccessToViewDataInTheClientCard(props.authData)) {
      return <>No Access</>
    }
  }

  return (
    <Stack>
        <CustomAlert
          errorMessage={props.errorMessage}
          isError={props.isError}
          resetError={props.resetError}
        />
      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
        <Stack spacing={4} sx={{ minWidth: 0 }}>
          {StackIdentityInfo}
          {StackContacts}
          {StackPayAllocations}
        </Stack>
        <Stack spacing={2}>
          {StackStatistics}
        </Stack>
        <Stack spacing={2} style={{ position: "relative" }}>

          {
            props.authData && hasAccessToBlockClients(props.authData)
              ? <>
                {client.status === "blocked" ? (
                  <Unblock clientId={clientId as any} />
                ) : (
                  <Block />
                )}
              </>
              : null
          }

          {mbanqDetails && props.authData && hasAccessToBlockClientsInMBanq(props.authData) && <>
            {mbanqDetails.isBlocked ? (
              <UnblockMbanq clientId={clientId as any} />
            ) : (
              <BlockMbanq />
            )}
          </>}

          {
            props.authData && hasAccessToCloseAccount(props.authData)
              ? <div style={{ position: "absolute", bottom: 0 }}>
                <CloseAccount
                  clientId={clientId as string}
                  status={client.status}
                />
              </div>
              : null
          }

        </Stack>
      </Box>

      <Box sx={{ minHeight: 550, margin: 2 }}>
        <TabContext value={detailsTabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={updateTabIndex} >
              <Tab label="Onboarding" value="1" />
              <Tab label="Update user" value="2" />
              <Tab label="Mbanq" value="3" />
              <Tab label="TX Limits" value="4" />
              <Tab label="Activity Log" value="5" />
              <Tab label="Cashback" value="6" />
              <Tab label="Advances" value="7" />
              <Tab label="DocV" value="8" />
              <Tab label="Other" value="9" />
            </TabList>
          </Box>
          <div className="client-page-tab-panel">
            <TabPanel value="1">{StackOnboardingDetails}</TabPanel>
            <TabPanel value="2">{StackUserEditor}</TabPanel>
            <TabPanel value="3">{StackPayrolls(payrollsList)}</TabPanel>
            <TabPanel value="4"><TransactionLimits /></TabPanel>
            <TabPanel value="5" className="activity-tab-panel"><ActivityLog /></TabPanel>
            <TabPanel value="6" className="activity-tab-panel"><ClientCashbackHistoryTable /></TabPanel>
            <TabPanel value="7" className="activity-tab-panel"><ClientAdvancesHistoryTable /></TabPanel>
            <TabPanel value="8" className={"activity-tab-panel"}><ClientDocVAttemptsTable /></TabPanel>
            <TabPanel value="9" className="activity-tab-panel"><OtherActionsTab /></TabPanel>
          </div>
        </TabContext>
      </Box>
    </Stack>
  );
};


const mapStateToProps = (state: AppState) => {
  return {
    authData: state.user.authData,
    isPhoneUpdateLoading: state.client.isPhoneUpdateLoading,
    errorMessage: state.error.errorMessage,
    isError: state.error.isError,
    IsNameUpdating: state.client.IsNameUpdating,
    tariffInfo: state.client.TariffInfo,
    isTariffInfoLoading: state.client.IsTariffInfoLoading,
    isAdvancesInfoLoading: state.clientAdvances.isLoadingTable,
    advancesHistory: state.clientAdvances.clientAdvancesHistory,
    IsDelayedBoostInfoLoading: state.client.IsDelayedBoostInfoLoading,
    DelayedBoostInfo: state.client.DelayedBoostInfo
  };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
  updateUserPhone,
  updateClientName,
  getClientTariffInfo,
  getClientAdvancesHistory,
  getClientDelayedBoostInfo,
  resetError
})(PageClient);

const StackPayrolls = (payrollsList: any) => (
  <Box>

    <Stack direction="row" sx={{ spacing: 0 } as any} >
      <Box sx={{ width: 90,  padding: 0.5 }}>Mbanq ID</Box>
      <Box sx={{ width: 180,  padding: 0.5 }}>Date</Box>
      <Box sx={{ width: 700, padding: 0.5 }}>Reference</Box>
      <Box sx={{ width: 100, padding: 0.5 }}>Amount</Box>
      <Box sx={{ width: 50, padding: 0.5 }}>Payroll</Box>
    </Stack>

    {payrollsList.map((x: any) =>
      <Stack direction="row" sx={{ spacing: 0 } as any} >
        <Box sx={{ width: 90, borderRight: '1px solid gray', padding: 0.5 }}>{x.externalId}</Box>
        <Box sx={{ width: 180, borderRight: '1px solid gray', padding: 0.5 }}>{x.createdAt}</Box>
        <Box sx={{ width: 700, borderRight: '1px solid gray', padding: 0.5 }} dangerouslySetInnerHTML={{ __html: x.formattedReference }}></Box>
        <Box sx={{ width: 100, borderRight: '1px solid gray', padding: 0.5 }}>{x.amount}</Box>
        <Box sx={{ width: 50, borderRight: '1px solid gray', padding: 0.5 }}>{(x.isPayroll ? '✓' : '✖')}</Box>
      </Stack>
    )}
  </Box>
);

function getTime(dateStr?: Date | undefined) {
  const date : Date | undefined = dateStr ? new Date(dateStr) : undefined;
  return date != null ? date.getTime() : 0;
}

function DefinitionPairLayoutWithTooltip({ dd, dt, tp, ...props }: any) {
  return (
      <Box {...props}>
        <Tooltip title={tp}>
        <Typography variant={"dt" as any}>{dt}:&nbsp;</Typography>
        </Tooltip>
        <Typography variant={"dd" as any}>{dd}</Typography>
      </Box>
  );
}

function DefinitionPairLayout({ dd, dt, ...props }: any) {
  return (
    <Box {...props}>
      <Typography variant={"dt" as any}>{dt}:&nbsp;</Typography>
      <Typography variant={"dd" as any}>{dd}</Typography>
    </Box>
  );
}

function TimelineItemOnboarding({
  step,
  errors,
  isCurrent,
  submitTime,
  clientStatus,
  stepData,
}: any) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { timeSubtitle, icon, contentSubtitle } = getTemplate({
    errors,
    isCurrent,
    submitTime,
    clientStatus,
  });

  return (
    <TimelineItem sx={{ alignItems: "flex-start" }}>
      <TimelineOppositeContent color="text.secondary" sx={{ minWidth: 200 }}>
        <Typography variant="subtitle1">
          <FormattedMessage id={`client.onboarding.step.${step}`} />
        </Typography>
        <Typography variant="caption">{timeSubtitle}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          color="inherit"
          sx={{ boxShadow: "none", marginTop: "4px" }}
        >
          {icon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ flex: 10 }}>
        {contentSubtitle === "Success" && stepData ? (
          <>
            <Link
              component="button"
              onClick={handleExpandClick}
              sx={{
                borderBottom: "1px dashed",
              }}
              underline="none"
              variant="subtitle1"
            >
              {contentSubtitle}
            </Link>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <JSONPretty data={stepData} />
            </Collapse>
          </>
        ) : (
          <Typography variant="subtitle1">{contentSubtitle}</Typography>
        )}
        {errors?.length ? (
          <Box>
            {errors.map((error: any, index: any) => (
              <Typography key={index} variant="subtitle2">
                {error}
              </Typography>
            ))}
          </Box>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
}

function getTemplate({ errors, isCurrent, submitTime, clientStatus }: any) {
  if (errors) {
    return {
      timeSubtitle: (
        <>
          <FormattedDate value={submitTime} />
          {", "}
          <FormattedTime value={submitTime} />
        </>
      ),
      icon: <ErrorOutlineRoundedIcon color="error" />,
      contentSubtitle: "Fail",
    };
  }

  if (isCurrent) {
    return {
      timeSubtitle: "Current step",
      icon: <PendingIcon color="info" />,
      contentSubtitle: null,
    };
  }

  if (submitTime) {
    return {
      timeSubtitle: (
        <>
          <FormattedDate value={submitTime} />
          {", "}
          <FormattedTime value={submitTime} />
        </>
      ),
      icon: <CheckCircleOutlineRoundedIcon color="success" />,
      contentSubtitle: "Success",
    };
  }

  if (clientStatus === "active") {
    return {
      icon: <CheckCircleOutlineRoundedIcon color="success" />,
      contentSubtitle: "Success",
    };
  }

  return {
    timeSubtitle: null,
    icon: <CircleOutlinedIcon color="action" />,
    contentSubtitle: null,
  };
}
