import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomAlert from '../../core/Alert/Alert';
import { resetError } from '../../Error/actions';
import { AppState } from '../../reducers';
import { AdminUser } from '../reducer';
import { addAdminUser, editAdminUser } from './actions';

export type AdminUserFormMode = 'add' | 'edit';

interface StateFromProps {
    errorMessage: string;
    isError: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

interface DispatchFromProps {
    editAdminUser: (adminUser: AdminUser) => void;
    addAdminUser: (adminUser: AdminUser) => void;
    resetError: () => void;
}

interface PassedProps {
    adminUser?: AdminUser;
    formMode: AdminUserFormMode;
}

const AdminUserForm = (props: StateFromProps & DispatchFromProps & PassedProps) => {
    const [isFormVisible, setIsFormVisible] = React.useState(false);

    const [adminUserFormValue, setAdminUserFormValue] = React.useState<AdminUser | undefined>(props.adminUser);

    useEffect(() => {
        setAdminUserFormValue(props.adminUser);
        setIsFormVisible(false);
    }, [props.isSaved]);

    const handleClose = () => {
        setAdminUserFormValue(props.adminUser);
        setIsFormVisible(false);
    }

    const handleSubmit = () => {
        if (props.formMode === 'edit') {
            props.editAdminUser(adminUserFormValue as AdminUser);
        }
        else {
            props.addAdminUser(adminUserFormValue as AdminUser);
        }
    }

    const handleFormItemChange = (input: any, objectKey: keyof AdminUser) => {
        const value = input.target.value;

        let adminUserFormValueCopy = { ...adminUserFormValue };

        adminUserFormValueCopy[objectKey] = value;

        setAdminUserFormValue(adminUserFormValueCopy as AdminUser);
    }

    return <>
        <Button
            variant="outlined"
            onClick={() => setIsFormVisible(true)}
            style={{ marginBottom: 10 }}
        >
            {props.formMode === 'edit' ? 'Edit' : 'Add User'}
        </Button>

        <Dialog open={isFormVisible} onClose={handleClose}>

            {props.isLoading ? <LinearProgress></LinearProgress> : null}

            <DialogTitle>{props.formMode === 'edit' ? 'Edit' : 'Add'} User</DialogTitle>
            <DialogContent>
                <CustomAlert
                    errorMessage={props.errorMessage}
                    isError={props.isError}
                    resetError={props.resetError}
                />

                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="text"
                            variant="standard"
                            value={adminUserFormValue?.email}
                            onChange={(input: any) => handleFormItemChange(input, 'email')}
                            disabled={props.isLoading}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            margin="dense"
                            id="password"
                            label="Password"
                            type="password"
                            variant="standard"
                            value={adminUserFormValue?.password}
                            onChange={(input: any) => handleFormItemChange(input, 'password')}
                            disabled={props.isLoading}
                        />
                    </Grid>
                    <Grid item xs={5}>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="firstName"
                            label="First Name"
                            type="text"
                            variant="standard"
                            value={adminUserFormValue?.firstName}
                            onChange={(input: any) => handleFormItemChange(input, 'firstName')}
                            disabled={props.isLoading}
                        />

                    </Grid>
                    <Grid item xs={5}>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="lastName"
                            label="Last Name"
                            type="text"
                            variant="standard"
                            value={adminUserFormValue?.lastName}
                            onChange={(input: any) => handleFormItemChange(input, 'lastName')}
                            disabled={props.isLoading}
                        />

                    </Grid>
                    <Grid item xs={8}>
                        <FormControl sx={{ m: 1, width: 200 }}>
                            <InputLabel id="role">Role</InputLabel>
                            <Select
                                labelId="role"
                                id="role"
                                value={adminUserFormValue?.role}
                                label="Role"
                                onChange={(input: any) => handleFormItemChange(input, 'role')}
                                disabled={props.isLoading}
                            >
                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                <MenuItem value={'Agent'}>Agent</MenuItem>
                                <MenuItem value={'LightAgent'}>Light Agent</MenuItem>
                                <MenuItem value={'Viewer'}>Viewer</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>{props.formMode === 'edit' ? 'Edit' : 'Add'}</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>;
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        errorMessage: state.error.errorMessage,
        isError: state.error.isError,
        isLoading: state.adminUser.isLoading,
        isSaved: state.adminUser.isSaved
    };
};

export default connect<StateFromProps, DispatchFromProps, PassedProps, AppState>(mapStateToProps, {
    editAdminUser,
    addAdminUser,
    resetError
})(AdminUserForm);
