import { Alert } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import CustomAlert from '../core/Alert/Alert';
import { AppState } from '../reducers';
import LegalDocumentsTable from './LegalDocumentsTable';

interface StateFromProps {
    errorMessage: string;
    isError: boolean;
}

const LegalDocumentsPage = (props: StateFromProps) => {
    return <>
        <CustomAlert
            errorMessage={props.errorMessage}
            isError={props.isError}
            resetError={() => null}
        />

        <LegalDocumentsTable />
    </>;
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        errorMessage: state.error.errorMessage,
        isError: state.error.isError
    };
};

export default connect<StateFromProps, any, any, AppState>(mapStateToProps, {})(LegalDocumentsPage);
