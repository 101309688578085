import * as React from 'react';
import UserUpdatesTable from './UserUpdates/UserUpdatesTable';
import ExternalCardsHistoryTable from './ExternalCardsHistory/ExternalCardsHistoryTable';
import { Box, Tab, Tabs } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TransferErrorLogsTable from './TransferErrorLogs/TransferErrorLogsTable';


interface PassedProps {

}

enum TabKeys {
    UserUpdates = 'user-updates',
    UserEvents = 'user-events',
    ExternalCards = 'external-cards',
    TrasnferErrorLogs = 'transfer-error-logs'
}

const ActivityLog = (props: PassedProps) => {

    const [activeTabKey, setActiveTabKey] = React.useState<TabKeys>(TabKeys.UserUpdates);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTabKey(newValue as TabKeys);
    };

    return <>
        <div className='div'>
            <TabContext value={activeTabKey}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        className='TabList'
                    >
                        <Tab value={TabKeys.UserUpdates} label="User Updates" className='tab1' />
                        <Tab value={TabKeys.UserEvents} label="User Events" />
                        <Tab value={TabKeys.ExternalCards} label="External Cards" />
                        <Tab value={TabKeys.TrasnferErrorLogs} label="Transfer errors" />
                    </TabList>
                </Box>
                <div className='activity-log-panels'>
                    <TabPanel value={TabKeys.UserUpdates} className='user-updates-panel'>
                        <UserUpdatesTable />
                    </TabPanel>
                    <TabPanel value={TabKeys.UserEvents} className='TabPanel2'>
                        
                    </TabPanel>
                    <TabPanel value={TabKeys.ExternalCards} className='TabPanel3'>
                      <ExternalCardsHistoryTable />
                    </TabPanel>
                    <TabPanel value={TabKeys.TrasnferErrorLogs} className='TabPanel4'>
                      <TransferErrorLogsTable />
                    </TabPanel>
                </div>
            </TabContext>
        </div>
    </>
}


export default ActivityLog;