import * as React from 'react';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../reducers';
import {getLegalDocuments, uploadLegalDocument} from './actions';
import {LegalDocument} from './reducer';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {IconButton} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface StateFromProps {
    legalDocuments?: LegalDocument[];
    isLoadingTable: boolean;
    isSaved: boolean;
}

interface DispatchFromProps {
    getLegalDocuments: () => void;
    uploadLegalDocument: (document_key: string, file: File) => void;
}





const LegalDocumentsTable = (props: StateFromProps & DispatchFromProps) => {

    useEffect(() => {
        props.getLegalDocuments();
    }, []);

    const onChangeHandler=(event: { target: { files: FileList | null; }; }, documentKey: string)=>{
        if(event.target.files !== null){
            const uploadedFile = event.target.files[0];
            props.uploadLegalDocument(documentKey, uploadedFile)
        }
    };

    const columns: GridColDef[] = [
        { field: 'key', headerName: 'Key', width: 350 },
        {
            field: 'title', headerName: 'Title', width: 350,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as LegalDocument;
                return <a target="_blank" href={typedRow.staticUrl}>{params.value}</a>
            }
        },
        {field: 'upload', headerName: 'Click to upload', width: 200,
            disableColumnMenu: true, sortable: false, hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => {
            const docKey = (params.row as LegalDocument).key;
            return <IconButton
                              color="primary" aria-label="upload updated document" component="label">
               <input
                   style={{ display: 'none' }}
                   hidden accept="pdf/*" type="file"
                   onChange={(event: any) => {onChangeHandler(event, docKey)}}
               />
               <UploadFileIcon />
            </IconButton>
        }}
    ];

    return <div style={{ height: 580, width: '100%' }}>
        <DataGrid
            rows={props.legalDocuments ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
            hideFooterSelectedRowCount={true}
        />
    </div>
}




const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        legalDocuments: state.legalDocument.legalDocuments,
        isLoadingTable: state.legalDocument.isLoadingTable,
        isSaved: state.legalDocument.isSaved
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getLegalDocuments, uploadLegalDocument
})(LegalDocumentsTable);