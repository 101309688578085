import ReactJSONPretty from "react-json-pretty";

import React from "react";

export default function JSONPretty({ data }) {
  return (
    <ReactJSONPretty
      theme={{
        main: "color:#66d9ef;background:#14263A;border-radius: 8px;padding:4px;white-space: pre-wrap;",
        error: "color:#66d9ef;background:#272822;",
        key: "color:yellow;",
        string: "color:#45eb88;",
        value: "color:#45eb88;",
        boolean: "color:#45eb88;",
      }}
      data={data}
    />
  );
}
