import { gql, useQuery } from "@apollo/client";
import { Breadcrumbs, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import LinkRouter from "../core/LinkRouter";
import { FormattedDate } from "react-intl";

export const CLIENT = gql`
  query Client($clientId: String!) {
    client(clientId: $clientId)
      @rest(type: "Client", path: "/clients/{args.clientId}") {
      firstName
      id
      lastName
      status
      blockingReason {
        id
        value
      }
      closingReason {
        id
        value
      }
      closedAt
    }
  }
`;

export default function PageClientToolbar(params) {
  const { clientId } = useParams();

  let { data } = useQuery(CLIENT, {
    variables: { clientId },
    fetchPolicy: "cache-only",
  });

  const Status = (
    <Typography component="strong" variant="inherit">
      <FormattedMessage
        id={`client.onboarding.status.${data?.client.status}`}
      />
    </Typography>
  );

  const getStatusColor = () => {
    switch (data?.client.status) {
      case "active":
        return "success";
      case "closed":
        return "error";

      default:
        return "info";
    }
  };
  return (
    <Stack justifyContent="space-between" direction="row" sx={{ width: 1 }}>
      <Breadcrumbs>
        <LinkRouter underline="hover" color="inherit" to="/clients">
          Clients
        </LinkRouter>
        <Typography color="text.primary" sx={{ fontWeight: 600, fontSize: 18 }}>
          {data?.client.firstName} {data?.client.lastName}
        </Typography>
      </Breadcrumbs>
      {data && (
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }} >
          <Typography variant="subtitle1" color="text.secondary">
            {data?.client.status === "blocked" ? (
              <Chip label={Status} color="error" />
            ) : (
              <Chip
                label={Status}
                color={getStatusColor()}
              />
            )}
            <>
              {" "}
              {data?.client.status === "blocked" || data?.client.status === "closed" ? 'by' : null}{" "}
              <Typography
                component="strong"
                color="text.primary"
                variant="inherit"
              >
                {getReasonText(data)}
              </Typography>

              {data?.client.closedAt ? " on " : null}
              {data?.client.closedAt ? <FormattedDate value={data?.client.closedAt} dateStyle="medium" /> : null}

          </>
        </Typography>
        </Stack>
  )
}
    </Stack >
  );
}

const getReasonText = (data) => {
  switch (data?.client.status) {
    case "blocked":
      return data?.client.blockingReason ? data?.client.blockingReason.value : null;
    case "closed":
      return data?.client.closingReason ? data?.client.closingReason.value : null;

    default:
      return "";
  }
}