import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
    Button,
} from "@mui/material";
import mockBlockReasons from "../mock-block-reasons.json";
import { DialogSubmitCloseAccount } from "./DialogSubmitCloseAccount";
import DialogConfirmCloseAccount from "./DialogConfirmCloseAccount";
import { FETCH_BLOCK_CLIENT_REASONS } from "./CloseAccountQueries";

interface PassedProps{
    clientId: string;
    status: string;
}

export default function CloseAccount(props: PassedProps) {
    const { data } = useQuery(FETCH_BLOCK_CLIENT_REASONS, {
        fetchPolicy: "cache-first",
    });

    const [isSubmitCloseAccountOpened, setIsSubmitCloseAccountOpened] = React.useState(false);
    const [isConfirmCloseAccountOpened, setIsConfirmCloseAccountOpened] = React.useState(false);

    return (
        <>
            <Button
                onClick={() => setIsConfirmCloseAccountOpened(true)}
                color="error"
                variant="contained"
                disabled={props.status === "closed"}
            >
                Close Account
            </Button>
            <DialogConfirmCloseAccount
                isOpen={isConfirmCloseAccountOpened}
                onClose={() => setIsConfirmCloseAccountOpened(false)}
                onOk={() => {
                    setIsConfirmCloseAccountOpened(false);
                    setIsSubmitCloseAccountOpened(true);
                }}
            />
            <DialogSubmitCloseAccount
                reasons={data?.reasons || mockBlockReasons}
                isOpen={isSubmitCloseAccountOpened}
                onClose={() => setIsSubmitCloseAccountOpened(false)}
                clientId={props.clientId}
            />
        </>
    );
}




