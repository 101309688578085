import { AdminUser } from '../AdminUsers/reducer';
import sendRequest from '../core/apiHelper';
import { throwError } from '../Error/actions';
import {ClientDelayedBoostInfo, ClientTariffInfo} from "./reducer";

// update client phone
export enum UPDATE_USER_PHONE {
    REQUEST = 'UPDATE_USER_PHONE_REQUEST',
    SUCCESS = 'UPDATE_USER_PHONE_SUCCESS',
}
export const updateUserPhoneRequest = () => {
    return {
        type: UPDATE_USER_PHONE.REQUEST
    };
};

export const updateUserPhoneSuccess = () => {
    return {
        type: UPDATE_USER_PHONE.SUCCESS
    };
};

type ChangePhoneNumberError = {
    errors: ChangePhoneNumberErrors;
}
type ChangePhoneNumberErrors = {
    newPhone: string[];
}

export const updateUserPhone = (clientId: string, newPhone: string) => {

    const body = { phoneNumber: newPhone };

    return (dispatch: any) => {
        dispatch(updateUserPhoneRequest());
        sendRequest(`/clients/${clientId}/phone`, 'PUT', body)
            .then((response: any) => {
                dispatch(updateUserPhoneSuccess());
            })
            .catch((error: any) => {
                const typedError = error as ChangePhoneNumberError;
                if (typedError) {
                    if (typedError.errors?.newPhone) {
                        dispatch(throwError(typedError.errors.newPhone[0]))
                        return;
                    }
                }

                dispatch(throwError("An error occurred during change user phone number"))
            });
    };
};

// update client name
export enum UPDATE_CLIENT_NAME {
    REQUEST = 'UPDATE_CLIENT_NAME_REQUEST',
    SUCCESS = 'UPDATE_CLIENT_NAME_SUCCESS',
}
export const updateClientNameRequest = () => {
    return {
        type: UPDATE_CLIENT_NAME.REQUEST
    };
};

export const updateClientNameSuccess = () => {
    return {
        type: UPDATE_CLIENT_NAME.SUCCESS
    };
};

type UpdateClientNameError = {
    errors: UpdateClientNameErrors;
}
type UpdateClientNameErrors = {
    firstName: string[];
    lastName: string[];
}

export const updateClientName = (clientId: string, firstName?: string, lastName?: string) => {

    const body: any = { };

    if(firstName){
        body["firstName"] = firstName;
    }
    if(lastName){
        body["lastName"] = lastName;
    }

    return (dispatch: any) => {
        dispatch(updateClientNameRequest());
        sendRequest(`/clients/${clientId}/full-name`, 'PUT', body)
            .then((response: any) => {
                dispatch(updateClientNameSuccess());
                window.location.reload();
            })
            .catch((error: any) => {
                const typedError = error as UpdateClientNameError;
                if (typedError) {
                    if (typedError.errors?.firstName) {
                        dispatch(throwError(typedError.errors.firstName[0]))
                        return;
                    }
                    if (typedError.errors?.lastName) {
                        dispatch(throwError(typedError.errors.lastName[0]))
                        return;
                    }
                }

                dispatch(throwError("An error occurred during update client name"))
            });
    };
};

// get client tariff info
export enum GET_CLIENT_TARIFF_INFO {
    REQUEST = 'GET_CLIENT_TARIFF_INFO_REQUEST',
    SUCCESS = 'GET_CLIENT_TARIFF_INFO_SUCCESS',
}
export const getClientTariffInfoRequest = () => {
    return {
        type: GET_CLIENT_TARIFF_INFO.REQUEST
    };
};

export const getClientTariffInfoSuccess = (tariffInfo: ClientTariffInfo) => {
 
    return {
        type: GET_CLIENT_TARIFF_INFO.SUCCESS,
        tariffInfo: tariffInfo
    };
};

export const getClientTariffInfo = (clientId: string) => {

    return (dispatch: any) => {
        dispatch(getClientTariffInfoRequest());
        sendRequest(`/clients/${clientId}/tariff-info`)
            .then((response: any) => {
                const responseParsed: ClientTariffInfo = 
                    { currentTariffName: response.currentTariffName, 
                        nextTariffName: response.nextTariffName,
                        nextPaymentDate: response.nextPaymentDate ? new Date(response.nextPaymentDate) : undefined }
                dispatch(getClientTariffInfoSuccess(responseParsed));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
}


// get client delayed boost info
export enum GET_CLIENT_DELAYED_BOOST_INFO {
    REQUEST = 'GET_CLIENT_DELAYED_BOOST_INFO_REQUEST',
    SUCCESS = 'GET_CLIENT_DELAYED_BOOST_INFO_SUCCESS',
}
export const getClientDelayedBoostInfoRequest = () => {
    return {
        type: GET_CLIENT_DELAYED_BOOST_INFO.REQUEST
    };
};

export const getClientDelayedBoostInfoSuccess = (delayedBoostInfo: ClientDelayedBoostInfo) => {

    return {
        type: GET_CLIENT_DELAYED_BOOST_INFO.SUCCESS,
        delayedBoostInfo: delayedBoostInfo
    };
};

export const getClientDelayedBoostInfo = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getClientDelayedBoostInfoRequest());
        sendRequest(`/clients/${clientId}/delayed-boost-info`)
            .then((response: any) => {
                dispatch(getClientDelayedBoostInfoSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
}