import { THROW_ERROR } from '../../Error/actions';
import { updateObject } from '../../helpers/updateObject';
import { PaginatedList } from '../../reducers';
import { GET_EXTERNAL_CARDS_HISTORY, GET_TRANSFER_ERROR_LOGS, GET_USER_UPDATES } from './actions';

export type UserUpdate = {
    id: string;
    updateFrom: string;
    updateTo: string;
    updateBy: string;
    updateAt: Date;
    notes: string;
    typeId: number;
}

export type ExternalCardHistoryItem = {
    id: string;
    message: string;
    action: string;
    createAt: Date;
    request: string;
    response: string;
    duplicateCards: string[];
}

export type TransferErrorLog = {
    id: string;
    userId: string;
    transferDirectionString: string;
    transferNetworkTypeString: string;
    exceptionHeader: string;
    exceptionBody: string;
    mbanqRawErrorPayload: string;
    timestamp: Date;
}

export type ActivityLogState = {
    userUpdates: UserUpdate[];
    externalCardsHistory: ExternalCardHistoryItem[];
    isLoadingTable: boolean;
    isLoading: boolean;
    isSaved: boolean;
    transferErrorLogsPaginatedList?: PaginatedList<TransferErrorLog>;
}

const initialState = {
    userUpdates: [],
    externalCardsHistory: [],
    isLoadingTable: false,
    isLoading: false,
    isSaved: false
}

export const reducer = (state: ActivityLogState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false });
        case GET_USER_UPDATES.REQUEST:
            return updateObject(state, { userUpdates: undefined, isLoadingTable: true });
        case GET_USER_UPDATES.SUCCESS:
            return updateObject(state, { userUpdates: action.userUpdates, isLoadingTable: false });
        case GET_EXTERNAL_CARDS_HISTORY.REQUEST:
            return updateObject(state, { externalCardsHistory: undefined, isLoadingTable: true });
        case GET_EXTERNAL_CARDS_HISTORY.SUCCESS:
            return updateObject(state, { externalCardsHistory: action.externalCardsHistory, isLoadingTable: false });
        case GET_TRANSFER_ERROR_LOGS.REQUEST:
            return updateObject(state, { transferErrorLogsPaginatedList: undefined, isLoadingTable: true });
        case GET_TRANSFER_ERROR_LOGS.SUCCESS:
            return updateObject(state, { transferErrorLogsPaginatedList: action.transferErrorLogsPaginatedList, isLoadingTable: false });

        default:
            return state;
    }
}