import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { refreshToken } from './actions';
import { AuthData } from './reducer';
import { getAuthDataFromSessionStorage } from './authHelper';
import LoginPage from './Login/LoginPage';

interface StateFromProps {
    isAuthorized: boolean;
    authData?: AuthData;
    refreshTokenTimeout?: any;
}

interface DispatchFromProps {
    refreshToken: (refreshToken: string) => any
}

const AuthProvider = (props: StateFromProps & DispatchFromProps & any) => {

    const [timeoutId, setTimeoutId] = useState<any>();

    useEffect(() => {
      const authData = getAuthDataFromSessionStorage();
      if(authData){
        props.refreshToken(authData?.refresh_token as string);
      }
    }, []);

    useEffect(() => {
      if (props.authData) {
        const timeoutId = setTimeout(
          () => props.refreshToken(props.authData?.refresh_token as string),
          //coule be some delays, better to have always updated token
          (props.authData?.expires_in - 1) * 1000);
  
        setTimeoutId(timeoutId);
      }
  
    }, [props.authData]);

    const auth = (<LoginPage />);

    const authData = getAuthDataFromSessionStorage();
 
    return authData ? props.children : auth;
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        isAuthorized: state.user.isAuthorized,
        authData: state.user.authData,
        refreshTokenTimeout: state.user.refreshTokenTimeout
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    refreshToken
})(AuthProvider);
