import jwt_decode from "jwt-decode";
import { AuthData } from "./reducer";

type JWTDecodedToken = {
    name: string;
    role: string;
}

export const decodeJwt = (token: string): JWTDecodedToken => {
    return jwt_decode(token) as JWTDecodedToken;
}

async function sendAuthRequest(
    url: string,
    httpMethod: 'GET' | 'POST' | 'DELETE' | 'PUT' = 'GET',
    body?: any,
    type: 'json' | 'form-data' = 'json'
) {

    return fetch(`${process.env.REACT_APP_OIDC_PROVIDER}${url}`,
        {
            method: httpMethod,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Accept: "application/json",
                'Content-Type': type == "json" ? "application/json" : "application/x-www-form-urlencoded",
            },
            body: getBody(body, type)
        },
    ).then(async response => {
        if (response.ok) {
            return response.json();
        }

        const errorData = await response.json();

        // ValidationException
        if (errorData?.errors?.entity && errorData?.errors?.entity[0]) {
            return Promise.reject(errorData?.errors?.entity[0]);
        }
        else {
            return Promise.reject(errorData);
        }
    })
}

export default sendAuthRequest;

const getBody = (
    body: any,
    type: 'json' | 'form-data'
): BodyInit | null | undefined => {
    if (!body) {
        return null;
    }

    if (type == 'json') {
        return JSON.stringify(body);
    }
    else if (type == "form-data") {
        return new URLSearchParams(body);
    }
}

const pathToSessionStorageAuthData = `oidc.user:${process.env.REACT_APP_OIDC_PROVIDER}:BNineAdmin`;

export const setAuthDataToSessionStorage = (authData: AuthData) => {
    localStorage.setItem(pathToSessionStorageAuthData, JSON.stringify(authData));
}

export const getAuthDataFromSessionStorage = (): AuthData | null => {
    const sessionStorageItem = localStorage.getItem(pathToSessionStorageAuthData);

    if(sessionStorageItem){
        return JSON.parse(sessionStorageItem) as AuthData;
    }
    
    return null;
}


export const removeAuthDataFromSessionStorage = () => {
    localStorage.removeItem(pathToSessionStorageAuthData);
}