import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { AdminUser } from '../AdminUsers/reducer';
import CustomAlert from '../core/Alert/Alert';
import { resetError } from '../Error/actions';
import { AppState } from '../reducers';
import { getUserProfile } from './actions';
import ChangePasswordForm from './ChangePassword/ChangePasswordForm';

interface StateFromProps {
    errorMessage: string;
    isError: boolean;
    userProfile?: AdminUser;
    isLoading: boolean;
}

interface DispatchFromProps {
    getUserProfile: () => void;
    resetError: () => void;
}

const ProfilePage = (props: StateFromProps & DispatchFromProps) => {

    useEffect(() => {
        props.getUserProfile();
    }, []);

    return <>
        <CustomAlert
            errorMessage={props.errorMessage}
            isError={props.isError}
            resetError={props.resetError}
        />

        {
            props.isLoading
                ? <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>

                : <Box sx={{ flexGrow: 1 }}>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={3}>
                            First Name:
                        </Grid>
                        <Grid item xs={9}>
                            {props.userProfile?.firstName}
                        </Grid>

                        <Grid item xs={3}>
                            Last Name:
                        </Grid>

                        <Grid item xs={9}>
                            {props.userProfile?.lastName}
                        </Grid>

                        <Grid item xs={3}>
                            Email:
                        </Grid>

                        <Grid item xs={9}>
                            {props.userProfile?.email}
                        </Grid>

                        <Grid item xs={3}>
                            Access Role:
                        </Grid>

                        <Grid item xs={9}>
                            {props.userProfile?.role}
                        </Grid>

                        <Grid item xs={3}>
                            <ChangePasswordForm />
                        </Grid>


                    </Grid>
                </Box>
        }



    </>;
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        errorMessage: state.error.errorMessage,
        isError: state.error.isError,
        userProfile: state.profile.userProfile,
        isLoading: state.profile.isLoading
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getUserProfile,
    resetError
})(ProfilePage);
