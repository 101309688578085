import { throwError } from "../Error/actions";
import { TenantData } from "./reducer";



export enum GET_TENANT {
    REQUEST = 'GET_TENANT_REQUEST',
    SUCCESS = 'GET_TENANT_SUCCESS',
}


const mappings: { [key: string]: { [key: string]: string } } = {
    "bnine": { "Name": "B9", "Website": "https://bnine.com/" },
    "poetryy": { "Name": "PoetrYY", "Website": "" },
    "eazy": { "Name": "Eazy", "Website": "" },
    "usnational": { "Name": "USA National", "Website": "" },
    "qorbis": { "Name": "QORBIS", "Website": "" },
    "manapacific": { "Name": "Mana Pacific", "Website": "" },    
    "p2p": { "Name": "P2P", "Website": "" },
    "booq": { "Name": "Booq", "Website": "" },
    "payhammer": { "Name": "Payhammer", "Website": "" },
    "token": { "Name": "Tokenizer", "Website": "" },
}

export const getTenantRequest = () => {
    return {
        type: GET_TENANT.REQUEST
    };
};

export const getTenantSuccess = (tenantData: TenantData) => {
    return (dispatch: any) => {
        dispatch({
            type: GET_TENANT.SUCCESS,
            tenantData: tenantData
        });
    }
};

export const getTenantData = () => {
    return (dispatch: any) => {
        dispatch(getTenantRequest())
        const tenantEnvVar: string | undefined = process.env.REACT_APP_TENANT
        if (tenantEnvVar !== undefined) {
            const tenantData = mappings[tenantEnvVar] as TenantData;
            dispatch(getTenantSuccess(tenantData))
        }
        else {
            dispatch(throwError("Environment settings were not configured properly"))
        }
    }
};