import * as React from 'react';
import { connect } from 'react-redux';
import { AppState, PaginatedList } from '../../../reducers';
import { DataGrid, GridCellValue, GridColDef, GridRenderCellParams, GridSortCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ExternalCardHistoryItem, TransferErrorLog, UserUpdate } from '../reducer';
import { getTransferErrorLogs } from '../actions';
import moment from 'moment';
import TransferErrorLogDetailsPopup from './TransferErrorLogDetailsPopup';

interface StateFromProps {
    transferErrorLogsPaginatedList?: PaginatedList<TransferErrorLog>;
    isLoadingTable: boolean;
}

interface DispatchFromProps {
    getTransferErrorLogs: (clientId: string) => void;
}

const TransferErrorLogsTable = (props: StateFromProps & DispatchFromProps) => {

    const { clientId } = useParams();
    
    useEffect(() => {
        if (clientId) {
            props.getTransferErrorLogs(clientId);
        }
    }, []);


    const [selectedEvent, setSelectedEvent] = useState<TransferErrorLog | undefined>(undefined);

    const columns: GridColDef[] = [
        {
            field: 'timestamp', headerName: 'Timestamp', width: 250,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as TransferErrorLog;

                return moment.utc(typedRow.timestamp).local().format('MMMM Do YYYY, h:mm:ss a')
            }
        },
        { field: 'transferDirectionString', headerName: 'Transfer Direction', width: 150 },
        { field: 'transferNetworkTypeString', headerName: 'Trasnfer Network', width: 150 },
        { field: 'exceptionHeader', headerName: 'Message', width: 650 },
        {
            field: 'id', headerName: '', width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as TransferErrorLog;

                return <Button onClick={() => setSelectedEvent(typedRow)}>See details</Button>;
            }
        },
    ];

    return <div style={{ height: 630, width: '100%' }}>
        <DataGrid
            rows={props.transferErrorLogsPaginatedList?.items ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
        />

        <TransferErrorLogDetailsPopup 
            item={selectedEvent} 
            isOpen={selectedEvent !== undefined}
            handleClose={() => setSelectedEvent(undefined)}
        />
    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        transferErrorLogsPaginatedList: state.activityLog.transferErrorLogsPaginatedList,
        isLoadingTable: state.activityLog.isLoadingTable
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getTransferErrorLogs
})(TransferErrorLogsTable);