import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import ClientDocumentsTable from './ClientDocumentsTable';

interface PassedProps {
    isOpen: boolean;
    handleClose: () => void;
}

const ClientDocumentsPopup = (props: PassedProps) => {
    return <>

        <Dialog
            open={props.isOpen}
            onClose={() => props.handleClose()}
            maxWidth={'lg'}
        >
            <DialogTitle>Client Documents</DialogTitle>

            <DialogContent>
                <ClientDocumentsTable />
            </DialogContent>

            <DialogActions>
                <Button onClick={() => props.handleClose()}>Close</Button>
            </DialogActions>
        </Dialog>


    </>;
}

export default ClientDocumentsPopup;
