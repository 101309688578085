import {GET_TENANT } from "./actions";
import { updateObject } from '../helpers/updateObject';
import {THROW_ERROR} from "../Error/actions";


export type TenantData = {
    Name?: string;
    Website?: string;
}


export type TenantMappingState = {
    tenantData?: TenantData,
    isLoading: boolean,
}

const initialState = {tenantData : {}, isLoading: false, isSaved: false};

export const reducer = (state: TenantMappingState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoading: false });
        case GET_TENANT.REQUEST:
            return updateObject(state, { isLoading: true, tenantData: {} });
        case GET_TENANT.SUCCESS:
            return updateObject(state, { isLoading: false, tenantData: action.tenantData });
        default:
            return state;
    }
}