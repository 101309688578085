import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import { ExternalCardLimits as ExternalCardLimitsType } from './Models/UserTransactionLimits';
import {useState} from "react";

interface PassedProps {
    externalCard: ExternalCardLimitsType;
}

export default function ExternalCardLimits(props: PassedProps) {
    const externalCard = props.externalCard;
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const pullUpcomingLimits = externalCard.pull.monthlyLimit.upcomingLimitsByDate;
    const pushUpcomingLimits = externalCard.push.monthlyLimit.upcomingLimitsByDate;

    return <>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                <TableHead >
                    <TableRow>
                        <TableCell><Typography variant="h3" gutterBottom></Typography></TableCell>
                        <TableCell align="right">Push</TableCell>
                        <TableCell align="right">Pull</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        externalCard ? <>
                            <TableRow
                                key={"dailyAmount"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Daily amount
                                </TableCell>
                                <TableCell align="right">{externalCard.push.dailyLimit.usedAmount} of {externalCard.push.dailyLimit.limitAmount}</TableCell>
                                <TableCell align="right">{externalCard.pull.dailyLimit.usedAmount} of {externalCard.pull.dailyLimit.limitAmount}</TableCell>
                            </TableRow>

                            <TableRow
                                key={"dailyCount"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Daily count
                                </TableCell>
                                <TableCell align="right">{externalCard.push.dailyLimit.transactionCount} of {externalCard.push.dailyLimit.transactionCountLimit}</TableCell>
                                <TableCell align="right">{externalCard.pull.dailyLimit.transactionCount} of {externalCard.pull.dailyLimit.transactionCountLimit}</TableCell>
                            </TableRow>

                            <TableRow
                                key={"weeklyAmount"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Weekly amount
                                </TableCell>
                                <TableCell align="right">{externalCard.push.weeklyLimit.usedAmount} of {externalCard.push.weeklyLimit.limitAmount}</TableCell>
                                <TableCell align="right">{externalCard.pull.weeklyLimit.usedAmount} of {externalCard.pull.weeklyLimit.limitAmount}</TableCell>
                            </TableRow>

                            <TableRow
                                key={"weeklyCount"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Weekly count
                                </TableCell>
                                <TableCell align="right">{externalCard.push.weeklyLimit.transactionCount} of {externalCard.push.weeklyLimit.transactionCountLimit}</TableCell>
                                <TableCell align="right">{externalCard.pull.weeklyLimit.transactionCount} of {externalCard.pull.weeklyLimit.transactionCountLimit}</TableCell>
                            </TableRow>

                            <TableRow
                                key={"monthlyAmount"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={handleToggleExpansion}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell component="th" scope="row">
                                    Monthly amount
                                </TableCell>
                                <TableCell align="right">{externalCard.push.monthlyLimit.usedAmount} of {externalCard.push.monthlyLimit.limitAmount}</TableCell>
                                <TableCell align="right">{externalCard.pull.monthlyLimit.usedAmount} of {externalCard.pull.monthlyLimit.limitAmount}</TableCell>
                            </TableRow>
                            {isExpanded && (<TableRow>
                                    <TableCell colSpan={3}>
                                        <TableContainer component={Paper} sx={{ width: '80%', marginLeft: 'auto' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Calculation Date</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Delta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableCell align="center">
                                                        <Typography fontWeight={400}>Upcoming pull limits</Typography></TableCell>
                                                    {
                                                        pullUpcomingLimits?.map((limit) => {
                                                            return <TableRow key={limit.upcomingDate}>
                                                                <TableCell>{new Date(limit.upcomingDate).toDateString()}</TableCell>
                                                                <TableCell>{limit.limitAmount}</TableCell>
                                                                <TableCell>{limit.delta}</TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                    <TableCell align="center">
                                                        <Typography fontWeight={400}>Upcoming push limits</Typography></TableCell>
                                                    {
                                                        pushUpcomingLimits?.map((limit) => {
                                                            return <TableRow key={limit.upcomingDate}>
                                                                <TableCell>{new Date(limit.upcomingDate).toDateString()}</TableCell>
                                                                <TableCell>{limit.limitAmount}</TableCell>
                                                                <TableCell>{limit.delta}</TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TableCell>
                                </TableRow>)}
                            <TableRow
                                key={"monthlyCount"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Monthly count
                                </TableCell>
                                <TableCell align="right">{externalCard.push.monthlyLimit.transactionCount} of {externalCard.push.monthlyLimit.transactionCountLimit}</TableCell>
                                <TableCell align="right">{externalCard.pull.monthlyLimit.transactionCount} of {externalCard.pull.monthlyLimit.transactionCountLimit}</TableCell>
                            </TableRow></>
                            : null
                    }


                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

