import { ClientCashbackHistory } from './reducer';
import sendRequest from '../../core/apiHelper';
import { throwError } from '../../Error/actions';

// get client cashback history
export enum GET_CLIENT_CASHBACK_HISTORY {
    REQUEST = 'GET_CLIENT_CASHBACK_HISTORY_REQUEST',
    SUCCESS = 'GET_CLIENT_CASHBACK_HISTORY_SUCCESS',
}
export const getClientCashbackHistoryRequest = () => {
    return {
        type: GET_CLIENT_CASHBACK_HISTORY.REQUEST
    };
};

export const getClientCashbackHistorySuccess = (clientCashbackHistory: ClientCashbackHistory) => {
    return {
        type: GET_CLIENT_CASHBACK_HISTORY.SUCCESS,
        clientCashbackHistory: clientCashbackHistory
    };
};

export const getClientCashbackHistory = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getClientCashbackHistoryRequest());
        sendRequest(`/client-cashback/${clientId}/history`)
            .then((response: any) => {
                dispatch(getClientCashbackHistorySuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};
