import * as React from 'react';
import { useParams } from "react-router-dom";
import { GetTransactionLimitsClient } from "./Queries/TransactionLimitsQuery";
import { useQuery } from "@apollo/client";
import ExternalCardLimits from './ExternalCardLimits';
import AchLimits from './AchLimits';
import { UserTransactionLimit } from './Models/UserTransactionLimits';
import InternalCardLimits from "./InternalCardLimits";
import {Typography} from "@mui/material";

export default function TransactionLimits(props: any) {
    const { clientId } = useParams();

    const { loading, error, data } = useQuery(GetTransactionLimitsClient, {
        variables: { clientId },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const transactionLimits = data.getTransactionLimits as UserTransactionLimit;
 
    const externalCard = transactionLimits.externalCardCurrentLimits;
    const ach = transactionLimits.achCurrentLimits;
    const internalCard = transactionLimits.internalCardCurrentLimits;

    return <>
        <div style={{ marginBottom: 20 }}>
            <Typography variant="h5" gutterBottom>Internal Card Transaction Limits</Typography>
            <InternalCardLimits internalCard={internalCard} />
        </div>
        <div style={{ marginBottom: 20 }}>
            <Typography variant="h5" gutterBottom>External Card Transaction Limits</Typography>
            <ExternalCardLimits externalCard={externalCard} />
        </div>
        <div style={{ marginBottom: 20 }}>
            <Typography variant="h5" gutterBottom>Outgoing ACH Transaction Limits</Typography>
            <AchLimits ach={ach} />
        </div>
    </>;
}