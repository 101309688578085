import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

interface PassedProps {
    isOpen: boolean;
    onClose: () => void;
    onOk: () => void;
}

const DialogConfirmCloseAccount = (props: PassedProps) => {

    return (
        <Dialog
            onClose={props.onClose}
            open={props.isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Are you sure you want to close the account? \n
                    Once account is closed, it can't be reopened.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onOk}>Yes, close account</Button>
                <Button onClick={props.onClose} autoFocus>
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default DialogConfirmCloseAccount;