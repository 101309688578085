import { THROW_ERROR } from '../Error/actions';
import { updateObject } from '../helpers/updateObject';
import { GET_ADMIN_USERS } from './actions';
import { ADD_ADMIN_USER, editAdminUser, EDIT_ADMIN_USER } from './AdminUserForm/actions';

export type AdminUser = {
    id: string;
    createdAt: Date;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    password?: string;
}

export type AdminUserState = {
    adminUsers: AdminUser[];
    isLoadingTable: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

const initialState = {
    adminUsers: [],
    isLoadingTable: false,
    isLoading: false,
    isSaved: false
}

const editAdminUserInState = (adminUsers: AdminUser[], editedUser: AdminUser) => {
    let adminUsersCopy = [...adminUsers];

    adminUsersCopy = adminUsersCopy.filter(x => x.id !== editedUser.id);

    adminUsersCopy.push(editedUser);

    return adminUsersCopy;
}

export const reducer = (state: AdminUserState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false, isSaved: false });
        case GET_ADMIN_USERS.REQUEST:
            return updateObject(state, { adminUsers: undefined, isLoadingTable: true });
        case GET_ADMIN_USERS.SUCCESS:
            return updateObject(state, { adminUsers: action.adminUsers, isLoadingTable: false });
        case EDIT_ADMIN_USER.REQUEST:
            return updateObject(state, { isLoading: true, isSaved: false });
        case EDIT_ADMIN_USER.SUCCESS:
            return updateObject(state, { isSaved: true, isLoading: false, adminUsers: editAdminUserInState(state.adminUsers, action.editedAdminUser) });
        case ADD_ADMIN_USER.REQUEST:
            return updateObject(state, { isLoading: true, isSaved: false });
        case ADD_ADMIN_USER.SUCCESS:
            return updateObject(state, { isSaved: true, isLoading: false, adminUsers: editAdminUserInState(state.adminUsers, action.newAdminUser) });


        default:
            return state;
    }
}