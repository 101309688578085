import { AdminUser } from '../reducer';
import sendRequest from '../../core/apiHelper';
import { throwError } from '../../Error/actions';

// edit admin user
export enum EDIT_ADMIN_USER {
    REQUEST = 'EDIT_ADMIN_USER_REQUEST',
    SUCCESS = 'EDIT_ADMIN_USER_SUCCESS',
}
export const editAdminUserRequest = () => {
    return {
        type: EDIT_ADMIN_USER.REQUEST
    };
};

export const editAdminUserSuccess = (editedAdminUser: AdminUser) => {
    return {
        type: EDIT_ADMIN_USER.SUCCESS,
        editedAdminUser: editedAdminUser
    };
};

export const editAdminUser = (adminUser: AdminUser) => {
    var data = {
        ...adminUser
    };

    return (dispatch: any) => {
        dispatch(editAdminUserRequest());
        sendRequest(`/admin-user/${adminUser.id}`, 'PUT', data)
            .then((response: any) => {
                dispatch(editAdminUserSuccess(response));
            })
            .catch((error: any) => {
                handleEditAdminUserErrors(dispatch, error);
            });
    };
};



// add admin user
export enum ADD_ADMIN_USER {
    REQUEST = 'ADD_ADMIN_USER_REQUEST',
    SUCCESS = 'ADD_ADMIN_USER_SUCCESS',
}
export const addAdminUserRequest = () => {
    return {
        type: ADD_ADMIN_USER.REQUEST
    };
};

export const addAdminUserSuccess = (newAdminUser: AdminUser) => {
    return {
        type: ADD_ADMIN_USER.SUCCESS,
        newAdminUser: newAdminUser
    };
};

export const addAdminUser = (adminUser: AdminUser) => {
    var data = {
        ...adminUser
    };

    return (dispatch: any) => {
        dispatch(addAdminUserRequest());
        sendRequest(`/admin-user`, 'POST', data)
            .then((response: any) => {
                dispatch(addAdminUserSuccess(response));
            })
            .catch((error: any) => {
                handleEditAdminUserErrors(dispatch, error);
            });
    };
};


// Error handling

type ChangePasswordError = {
    errors: NewPasswordFieldError | undefined;
}
type NewPasswordFieldError = {
    newPassword: string[] | undefined;
    password: string[] | undefined;
    email: string[] | undefined;
    role: string[] | undefined;
    firstName: string[] | undefined;
    lastName: string[] | undefined;
}

const handleEditAdminUserErrors = (dispatch: any, error: any) => {
    const typedError = error as ChangePasswordError;
    if (typedError.errors !== undefined) {
        if (typedError.errors.password !== undefined) {
            dispatch(throwError(typedError.errors.password[0]))
            return;
        }
        else if (typedError.errors.newPassword !== undefined) {
            dispatch(throwError(typedError.errors.newPassword[0]))
            return;
        }
        else if (typedError.errors.email !== undefined) {
            dispatch(throwError(typedError.errors.email[0]))
            return;
        }
        else if (typedError.errors.firstName !== undefined) {
            dispatch(throwError(typedError.errors.firstName[0]))
            return;
        }
        else if (typedError.errors.lastName !== undefined) {
            dispatch(throwError(typedError.errors.lastName[0]))
            return;
        }
        else if (typedError.errors.role !== undefined) {
            dispatch(throwError(typedError.errors.role[0]))
            return;
        }
    }

    dispatch(throwError("An error occurred during editing user"))
}