import { THROW_ERROR } from '../Error/actions';
import { updateObject } from '../helpers/updateObject';
import { GET_CLIENT_DOCUMENTS } from './actions';


export enum DocumentUiType {
    DocumentOriginals = "DocumentOriginals",
    Selfie = "Selfie",
}


export type ClientDocument = {
    id: string;
    fileName: string;
    documentTypeKey: string;
    documentUiType: DocumentUiType; 
    documentSide: string;
    createdOn: Date;
}

export type ClientDocumentState = {
    clientDocuments: ClientDocument[];
    isLoadingTable: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

const initialState = {
    clientDocuments: [],
    isLoadingTable: false,
    isLoading: false,
    isSaved: false
}

export const reducer = (state: ClientDocumentState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false });
        case GET_CLIENT_DOCUMENTS.REQUEST:
            return updateObject(state, { clientDocuments: undefined, isLoadingTable: true });
        case GET_CLIENT_DOCUMENTS.SUCCESS:
            return updateObject(state, { clientDocuments: action.clientDocuments, isLoadingTable: false });

        default:
            return state;
    }
}