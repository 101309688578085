import PropTypes from "prop-types";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CLIENT } from "../PageClient";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { useParams } from "react-router";
import mockBlockReasons from "./mock-block-reasons.json";

const FETCH_BLOCK_CLIENT_REASONS = gql`
  query FetchBlockClientReasons {
    reasons @rest(type: "BlockReason", path: "/block-client-reasons") {
      id
      value
      order
    }
  }
`;

export default function Block() {
  const { data } = useQuery(FETCH_BLOCK_CLIENT_REASONS, {
    fetchPolicy: "cache-first",
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} color="warning" variant="contained">
        Block Mbanq
      </Button>
      <DialogSelectBlockReason
        reasons={data?.reasons || mockBlockReasons}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

const BLOCK_CLIENT_MBANQ = gql`
  mutation BlockMbanq($clientId: ID!, $reasonId: ID!) {
    blockClient(clientId: $clientId, input: { reasonId: $reasonId })
      @rest(
        type: "Client"
        method: "POST"
        path: "/clients/{args.clientId}/saving-account/block"
      ) {
      NoResponse
    }
  }
`;

DialogSelectBlockReason.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  reasons: PropTypes.array,
};
function DialogSelectBlockReason({ reasons = [], onClose, open }) {
  const { clientId } = useParams();

  const [blockMbanq, { loading }] = useMutation(BLOCK_CLIENT_MBANQ, {
    variables: { clientId },
    refetchQueries: [CLIENT],
    onCompleted: handleClose,
    onError: (error) => {
      handleClose();
      alert(error)
    },
  });

  function handleClose() {
    onClose();
  }

  function handleListItemClick(reason) {
    blockMbanq({ variables: { reasonId: reason.id } });
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Choose the block reason</DialogTitle>

      <List>
        {reasons.map((r) => (
          <ListItem
            disabled={loading}
            button
            onClick={() => handleListItemClick(r)}
            key={r.id}
            sx={{ px: 3 }}
          >
            <ListItemText primary={r.value} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
