import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { getAdminUsers } from './actions';
import { AdminUser } from './reducer';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AdminUserForm from './AdminUserForm/AdminUserForm';

interface StateFromProps {
    adminUsers?: AdminUser[];
    isLoadingTable: boolean;
    isSaved: boolean;
}

interface DispatchFromProps {
    getAdminUsers: () => void;
}

const AdminUsersTable = (props: StateFromProps & DispatchFromProps) => {

    useEffect(() => {
        props.getAdminUsers();
    }, []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 350 },
        { field: 'email', headerName: 'Email', width: 300 },
        { field: 'firstName', headerName: 'First Name', width: 200 },
        { field: 'lastName', headerName: 'Last Name', width: 200 },
        { field: 'role', headerName: 'Role', width: 150 },
        {
            field: 'action', headerName: '', width: 350,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as AdminUser;

                return <AdminUserForm adminUser={typedRow} formMode={'edit'}/>
            }
        },
    ];

    return <div style={{ height: 630, width: 1500 }}>
        <AdminUserForm formMode={'add'}/>
        <DataGrid
            rows={props.adminUsers ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
            sortModel={[
                    {
                        field: 'firstName',
                        sort: 'asc',
                    },
                ]}
        />
    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        adminUsers: state.adminUser.adminUsers,
        isLoadingTable: state.adminUser.isLoadingTable,
        isSaved: state.adminUser.isSaved
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getAdminUsers
})(AdminUsersTable);