import { LegalDocument } from './reducer';
import sendRequest from '../core/apiHelper';
import { throwError } from '../Error/actions';
import sendFile from '../core/fileHelper'

// get legal documents
export enum GET_LEGAL_DOCUMENTS {
    REQUEST = 'GET_LEGAL_DOCUMENTS_REQUEST',
    SUCCESS = 'GET_LEGAL_DOCUMENTS_SUCCESS',
}
export const getLegalDocumentsRequest = () => {
    return {
        type: GET_LEGAL_DOCUMENTS.REQUEST
    };
};

export const getLegalDocumentsSuccess = (legalDocuments: LegalDocument[]) => {
    return {
        type: GET_LEGAL_DOCUMENTS.SUCCESS,
        legalDocuments: legalDocuments
    };
};

export const getLegalDocuments = () => {
    return (dispatch: any) => {
        dispatch(getLegalDocumentsRequest());
        sendRequest(`/legal-documents`)
            .then((response: any) => {
                dispatch(getLegalDocumentsSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};


export enum UPLOAD_LEGAL_DOCUMENT {
    REQUEST = 'UPLOAD_LEGAL_DOCUMENT_REQUEST',
    SUCCESS = 'UPLOAD_LEGAL_DOCUMENT_SUCCESS',
}
export const uploadLegalDocumentRequest = () => {
    return {
        type: UPLOAD_LEGAL_DOCUMENT.REQUEST
    };
};

export const uploadLegalDocumentSuccess = () => {
    return {
        type: UPLOAD_LEGAL_DOCUMENT.SUCCESS,
    };
};

export const uploadLegalDocument = (document_key: string, file: File) => {

    var data = new FormData()
    data.append('File', file)
    data.append('DocumentKey', document_key)

    return (dispatch: any) => {
        dispatch(uploadLegalDocumentRequest());
        sendFile(`/legal-documents/update`, data)
            .then(() => {
                dispatch(uploadLegalDocumentSuccess());
                window.location.reload();
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};
