import { AppBar, IconButton, Toolbar } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import React from "react";
import { DRAWER_WIDTH } from "../App";

Appbar.propTypes = {
  setMobileOpen: PropTypes.func,
  mobileOpen: PropTypes.bool,
  children: PropTypes.node,
};
export default function Appbar({ setMobileOpen, mobileOpen, children }) {
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <AppBar
      color="transparent"
      elevation={0}
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        ml: { sm: `${DRAWER_WIDTH}px` },
      }}
    >
      <Toolbar disableGutters>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          // edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        {children}
      </Toolbar>
    </AppBar>
  );
}
