import {
    Alert,
    Box,
    Button, Collapse, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, Grid, IconButton, LinearProgress, TextField
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomAlert from '../../core/Alert/Alert';
import { resetError } from '../../Error/actions';
import { AppState } from '../../reducers';
import { changePassword } from '../actions';

interface StateFromProps {
    errorMessage: string;
    isError: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

interface DispatchFromProps {
    changePassword: (oldPassword: string, newPassword: string, newPasswordSecondInput: string) => void;
    resetError: () => void;
}

const ChangePasswordForm = (props: StateFromProps & DispatchFromProps) => {
    const [isFormVisible, setIsFormVisible] = React.useState(false);

    const [oldPassword, setOldPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [newPasswordSecondInput, setNewPasswordSecondInput] = React.useState<string>("");

    useEffect(() => {
        setIsFormVisible(false);
    }, [props.isSaved]);

    const handleClose = () => {
        setNewPassword("");
        setOldPassword("");
        setNewPasswordSecondInput("");

        setIsFormVisible(false);
    }

    const handleSubmit = () => {
        props.changePassword(oldPassword, newPassword, newPasswordSecondInput);

        setNewPassword("");
        setOldPassword("");
        setNewPasswordSecondInput("");
    }

    return <>
        <Button
            variant="outlined"
            onClick={() => setIsFormVisible(true)}
            style={{ marginBottom: 10 }}
        >
            Change Password
        </Button>

        <Dialog open={isFormVisible} onClose={handleClose}>

            {props.isLoading ? <LinearProgress></LinearProgress> : null}

            <DialogTitle>Change Password</DialogTitle>
            <DialogContent style={{ width: 400 }}>
                <CustomAlert
                    errorMessage={props.errorMessage}
                    isError={props.isError}
                    resetError={props.resetError}
                />

                Password should be At least 7 chars, at least 1 uppercase char (A-Z), at least 1 number (0-9) and at least one special char

                <TextField
                    margin="dense"
                    id="oldPassword"
                    label="Old Password"
                    type="password"
                    variant="standard"
                    value={oldPassword}
                    fullWidth
                    onChange={(input: any) => setOldPassword(input.target.value)}
                    disabled={props.isLoading}
                />

                <TextField
                    margin="dense"
                    id="newPassword"
                    label="New Password"
                    type="password"
                    variant="standard"
                    value={newPassword}
                    fullWidth
                    onChange={(input: any) => setNewPassword(input.target.value)}
                    disabled={props.isLoading}
                />

                <TextField
                    margin="dense"
                    id="newPasswordSecondInput"
                    label="New Password"
                    type="password"
                    variant="standard"
                    value={newPasswordSecondInput}
                    fullWidth
                    onChange={(input: any) => setNewPasswordSecondInput(input.target.value)}
                    disabled={props.isLoading}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Submit</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>;
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        errorMessage: state.error.errorMessage,
        isError: state.error.isError,
        isLoading: state.adminUser.isLoading,
        isSaved: state.adminUser.isSaved
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    changePassword,
    resetError
})(ChangePasswordForm);
