import * as React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import { InternalCardLimits as InternalCardLimitsType, TransactionLimit } from './Models/UserTransactionLimits';
import {makeStyles} from "@mui/styles";

interface PassedProps {
    internalCard: InternalCardLimitsType | null;
}

const calculateRemainingAmount = (limit: { usedAmount: number; limitAmountSetByUser: number; limitAmount: number }) => {
    const userLimit = limit.limitAmountSetByUser ?? limit.limitAmount;
    return userLimit - limit.usedAmount;
};

type LimitPeriod = 'dailyLimit' | 'weeklyLimit' | 'monthlyLimit';


const useStyles = makeStyles({
    tabs: {
        borderBottom: '1px solid #ccc',
    },
    tab: {
        border: '1px solid #ccc',
        borderBottom: 'none',
        borderRadius: '8px 8px 0 0', // Rounded corners
        '&.physical': {
            backgroundColor: '#009C3D',
            color: '#fff',
        },
        '&.virtual': {
            backgroundColor: '#EC217A',
            color: '#fff',
        },
        '&.unselected': {
            opacity: 0.2,
        },
    },
    indicator: {
        display: 'none', // Hide the blue line
    },
});

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const renderLimitsTable = (title: string, limits: TransactionLimit) => (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} aria-label={`${title.toLowerCase()} table`}>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: '200px' }}><Typography variant="h3" gutterBottom>{title}</Typography></TableCell>
                    <TableCell align="right">Remaining Amount</TableCell>
                    <TableCell align="right">User Limit</TableCell>
                    <TableCell align="right">Max Limit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(['dailyLimit', 'weeklyLimit', 'monthlyLimit'] as LimitPeriod[]).map((period) => (
                    <TableRow key={period}>
                        <TableCell component="th" scope="row" sx={{ width: '200px' }}>{
                            capitalizeFirstLetter(period.replace('Limit', ' amount'))
                        }</TableCell>
                        <TableCell align="right">{calculateRemainingAmount(limits[period])}</TableCell>
                        <TableCell align="right">{limits[period].limitAmountSetByUser}</TableCell>
                        <TableCell align="right">{limits[period].limitAmount}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export default function InternalCardLimits(props: PassedProps) {
    const { internalCard } = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const classes = useStyles();

    if (!internalCard) {
        return <Typography variant="h6">No internal card limits available.</Typography>;
    }

    const tabs = [];
    if (internalCard.physicalCard) {
        tabs.push({ label: `Physical ...${internalCard.physicalCard.cardLastDigits}`, limits: internalCard.physicalCard.limits, type: 'physical' });
    }
    if (internalCard.virtualCard) {
        tabs.push({ label: `Virtual ...${internalCard.virtualCard.cardLastDigits}`, limits: internalCard.virtualCard.limits, type: 'virtual' });
    }

    if (tabs.length === 0) {
        return <Typography variant="h6">No internal card limits available.</Typography>;
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            {tabs.length > 1 && (
                <Tabs value={selectedTab} onChange={handleChange} classes={{ indicator: classes.indicator }}>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            className={`${classes.tab} ${tab.type} ${selectedTab !== index ? 'unselected' : ''}`}
                        />
                    ))}
                </Tabs>
            )}
            {renderLimitsTable('Spending Limits', tabs[selectedTab].limits.operations)}
            {renderLimitsTable('ATM Limits', tabs[selectedTab].limits.withdrawals)}
        </>
    );
}