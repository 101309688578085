import { Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab, Tabs, TextField } from '@mui/material';
import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import sendRequest from '../../../core/apiHelper';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';


interface PassedProps {

}

type StatementQuickOptionTypes = 'this-day' | 'month' | 'three-months' | 'three-months' | 'six-months'  | 'one-year';

const GenerateAccountStatementComponent = (props: PassedProps) => {

    const { clientId } = useParams();

    const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [selectedOption, setSelectedOption] = React.useState<StatementQuickOptionTypes | null>(null);
    const [startingDate, setStartingDate] = React.useState<Dayjs | null>(null);
    const [endingDate, setEndingDate] = React.useState<Dayjs | null>(null);
  
    
    const getQuickOptionVariantStyle = (option: StatementQuickOptionTypes): 'filled' | 'outlined' => {
        if(selectedOption === option){
            return "filled";
        }

        return "outlined";
    }

    const closePopup = () => {

        console.log(startingDate, endingDate);
        console.log(selectedOption);

        setSelectedOption('this-day');

        setIsPopupOpen(false)
    }

    const changeSelectedOption = (option: StatementQuickOptionTypes) => {
        setStartingDate(null);
        setEndingDate(null);

        setSelectedOption(option);
    }

    const changeStartingDate = (value: Dayjs | null) => {
        setSelectedOption(null);

        setStartingDate(value);
    }

    const changeEndingDate = (value: Dayjs | null) => {
        setSelectedOption(null);

        setEndingDate(value);
    }

    const generateAndDownloadStatement = () => {

        //operate by js Date, DatePicker is using DayJs so we convert from it
        let startDate = startingDate?.toDate();
        let endDate = endingDate?.toDate();

        if(selectedOption){
            switch (selectedOption) {
                case 'this-day':
                    startDate = moment().startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'month':
                    startDate = moment().startOf('month').toDate();
                    endDate = moment().add(1, 'months').startOf('month').toDate();
                    break;
                case 'one-year':
                    startDate = moment().startOf('year').toDate();
                    endDate = moment().endOf('year').toDate();
                    break;
                case 'six-months':
                    startDate = moment().subtract(6, 'months').endOf('month').toDate();
                    endDate = moment().add(1, 'months').startOf('month').toDate();
                    break;
                case 'three-months':
                    startDate = moment().subtract(2, 'months').startOf('month').toDate();
                    endDate = moment().add(1, 'months').startOf('month').toDate();
                    break;
                default:
                    break;
            }
        }

        const formattedStartDate = moment(startDate).format();
        const fotmattedEndDate = moment(endDate).format();

        const query = `?startDate=${formattedStartDate}&endDate=${fotmattedEndDate}`;

        setIsLoading(true)

        sendRequest(`/clients/${clientId}/saving-account/statement/download${query}`)
            .then((response: any) => {
            
                //create byte array from base 64 string
                var binaryString = window.atob(response.Data);
                var binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                   var ascii = binaryString.charCodeAt(i);
                   bytes[i] = ascii;
                }

                //build blob link to pack file data in to html download link
                var blob = new Blob([bytes], {type: "application/pdf"});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.Name;

                //downlod to a web browser
                link.click();

                setIsLoading(false)
            })
            .catch((error: any) => {
                alert(`Error has been occured during download document, error: ${error}`);
                setIsLoading(false)
            });
    }

    const loadingView = <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>;

    return <>
        <div>
        <Grid container spacing={2} margin={3}>
            <Grid item xs={5}>
               <Button variant="outlined" onClick={() => setIsPopupOpen(true)}>Issue Account Statement</Button>
            </Grid>
        </Grid>

        <Dialog
            open={isPopupOpen}
            onClose={() => closePopup()}
            maxWidth={'lg'}
         >
         <DialogTitle>Issue Account Statement</DialogTitle>

         <DialogContent>
             <p>Quick options:</p>
             <Chip variant={getQuickOptionVariantStyle('this-day')} label="This day" style={{margin: 5}} onClick={() => changeSelectedOption('this-day')}/>
             <Chip variant={getQuickOptionVariantStyle('month')} label="Month" style={{margin: 5}} onClick={() => changeSelectedOption('month')} />
             <Chip variant={getQuickOptionVariantStyle('three-months')} label="Three months" style={{margin: 5}} onClick={() => changeSelectedOption('three-months')} />
             <Chip variant={getQuickOptionVariantStyle('six-months')} label="Six months" style={{margin: 5}} onClick={() => changeSelectedOption('six-months')} />
             <Chip variant={getQuickOptionVariantStyle('one-year')} label="One year" style={{margin: 5}} onClick={() => changeSelectedOption('one-year')} />
             <br/>
             <p>Date range:</p>
 
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                 <DatePicker
                   label="Start date"
                   value={startingDate}
                   onChange={(newValue) => {
                     changeStartingDate(newValue);
                   }}
                   renderInput={(params) => <TextField {...params} />}
                 />
                 <span style={{margin: 5}}>
                     <DatePicker
                       label="End date"
                       value={endingDate}
                       onChange={(newValue) => {
                         changeEndingDate(newValue);
                       }}
                       renderInput={(params) => <TextField {...params} />}
                   
                      />
                 </span>
          
            </LocalizationProvider>
       

            

         </DialogContent>

         <DialogActions>
             <LoadingButton 
              loading={isLoading} 
              onClick={() => generateAndDownloadStatement()} 
            >
              Generate Statement
            </LoadingButton>

             <Button onClick={() => closePopup()}>Close</Button>
         </DialogActions>
        </Dialog>

        </div>
    </>
}


export default GenerateAccountStatementComponent;