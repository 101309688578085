import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  ApolloError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";

const restLink = new RestLink({
  uri: process.env.REACT_APP_ADMIN_API,
  customFetch: (requestInfo, init) => customFetch(requestInfo, init),
});

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const user = localStorage.getItem(
      `oidc.user:${process.env.REACT_APP_OIDC_PROVIDER}:BNineAdmin`
    );
    
    if(user){
      const { access_token, user_name } = JSON.parse(user);

      return {
        headers: {
          ...headers,
          Accept: "application/json",
          Authorization: `Bearer ${access_token}`,
          user_name
        },
      };
    }
    

  
  });
  return forward(operation);
});

const errorLink = onError(
  ({ forward, graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.log(
          "🚀 ~ file: WrapperApollo.jsx ~ line 35 ~ errorLink ~ error",
          error
        );
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([authRestLink, errorLink, restLink]),
  cache: new InMemoryCache(),
});

async function customFetch(requestInfo, init) {
  const response = await fetch(requestInfo, init);

  if (response.status === 404) {
    throw new Error("404 Not Found");
  } 
  
  if (response.status === 400) {
    const {errors} = await response.json()
    const errorMessages = Object.keys(errors).reduce((p, n) => [...p, ...errors[n]], [])
    throw new ApolloError({ clientErrors: errorMessages.map(e => new Error(e)) })
  }
  return response;
}

export default function WrapperApollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
