import { gql, useQuery, useMutation } from "@apollo/client";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { connect } from "react-redux";
import { AppState } from "../reducers";
import { AuthData } from "../Auth/reducer";
import { hasEditAccessToPayrollKeywords, hasViewAccessToPayrollKeywords } from "../Auth/accessLevelsHelper";


export const PAYROLL_KEYWORDS = gql`
  query PayrollKeywords {
    payrollKeywords
      @rest(
        type: "[String]",
        path: "/loan-settings/principal-keywords"
      )
  }
`;

export const ADD_PAYROLL_KEYWORD = gql`
  mutation AddPayrollKeyword($keyword: String!) {
    payrollKeywords(input: { keyword: $keyword })
      @rest(
        type: "Some"
        method: "POST"
        path: "/loan-settings/principal-keywords"
      ) {
      NoResponse
    }
  }
`;

interface StateFromProps{
  authData?: AuthData;
}

const PageKeywords = (props: StateFromProps) => {
  const { data } = useQuery(PAYROLL_KEYWORDS)
  const hasAccessToEdit = props.authData && hasEditAccessToPayrollKeywords(props.authData);

  const [newKeyword, setNewKeyword] = useState(null)
  const [addKeyword] = useMutation(ADD_PAYROLL_KEYWORD, {
    refetchQueries: [PAYROLL_KEYWORDS],
    onError: (error) => {
      alert(error)
    },
  });
  return (
    <Stack>
      {hasAccessToEdit && <Box sx={{ margin: 5 }}>
        <TextField
          label="New Keyword"
          id="standard-size-normal"
          value={newKeyword}
          variant="standard"
          onChange={(e: any) => setNewKeyword(e.target.value)}
        />
        <Button variant="contained" size="large" sx={{ margin: 1 }} onClick={(e) => { addKeyword({ variables: { keyword: newKeyword } }); setNewKeyword("" as any); }} >Add keyword</Button>
      </Box>}
      {data?.payrollKeywords && <Box sx={{ display: 'grid', gridAutoFlow: 'column', gridTemplateRows: `repeat(${Math.ceil(data?.payrollKeywords.length / 5)}, auto)` }}>
        {data?.payrollKeywords.map((k: any) => <Box key={k}>{k}</Box>)}
      </Box>}
    </Stack>
  )
}


const mapStateToProps = (state: AppState) => {
  return {
    authData: state.user.authData
  };
};

export default connect<StateFromProps, any, any, AppState>(mapStateToProps, {})(PageKeywords);