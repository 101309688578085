export const USER_STATUS_VALUES = [
  "pendingPersonalInformation",
  "pendingAddress",
  "pendingSSN",
  "pendingDocumentVerification",
  "pendingSummary",
  "pendingAgreement",
  "pendingApproval",
  "active",
  "declined",
  "blocked",
];

export const DAYS_TO_SEARCH_OVERDUE_ADVANCES = 30;
export const ACCEPTABLE_ADVANCE_OVERDUE_IN_DAYS = 20;
