import { ClientDocument } from './reducer';
import sendRequest from '../core/apiHelper';
import { throwError } from '../Error/actions';

// get client documents
export enum GET_CLIENT_DOCUMENTS {
    REQUEST = 'GET_CLIENT_DOCUMENTS_REQUEST',
    SUCCESS = 'GET_CLIENT_DOCUMENTS_SUCCESS',
}
export const getClientDocumentsRequest = () => {
    return {
        type: GET_CLIENT_DOCUMENTS.REQUEST
    };
};

export const getClientDocumentsSuccess = (clientDocuments: ClientDocument[]) => {
    return {
        type: GET_CLIENT_DOCUMENTS.SUCCESS,
        clientDocuments: clientDocuments
    };
};

export const getClientDocuments = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getClientDocumentsRequest());
        sendRequest(`/client-documents/${clientId}`)
            .then((response: any) => {
                dispatch(getClientDocumentsSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};
