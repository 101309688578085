import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClientAdvancesHistory, AdvancesHistoryEntry } from '../reducer';
import { getClientAdvancesHistory } from '../actions';
import moment from 'moment';
import { getArrayWithIds } from '../../../helpers/tableHelper';


interface StateFromProps {
    clientAdvancesHistory?: ClientAdvancesHistory;
    isLoadingTable: boolean;
}

interface DispatchFromProps {
    getClientAdvancesHistory: (clientId: string) => void;
}

const ClientAdvancesHistoryTable = (props: StateFromProps & DispatchFromProps) => {
    
    const columns: GridColDef[] = [
        {
          field: 'loanId', 
          headerName: 'Loan ID', 
          width: 120 
        },
        {
            field: 'disbursedAt',
            headerName: 'Open Date',
            width: 200,
            renderCell: (params) => {
                const typedRow = params.row as AdvancesHistoryEntry;
                return typedRow.disbursedAt ? moment.utc(typedRow.disbursedAt).format('MMMM Do YYYY') : undefined
               
            },
        },
        {
            field: 'amountUsed',
            headerName: 'Amount Used',
            width: 130,
        },
        {
            field: 'originalAmount',
            headerName: 'Original Amount',
            width: 150,
        },
        {
            field: 'boost',
            headerName: 'Boost',
            width: 80,
        },
        {
            field: 'remainingAmount',
            headerName: 'Remaining Amount',
            width: 170,
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            width: 200,
            renderCell: (params) => {
                const typedRow = params.row as AdvancesHistoryEntry;
                return typedRow.dueDate ? moment.utc(typedRow.dueDate).format('MMMM Do YYYY') : undefined
            },
        },
        {
            field: 'closedDate',
            headerName: 'Closed Date',
            width: 200,
            renderCell: (params) => {
                const typedRow = params.row as AdvancesHistoryEntry;
                return typedRow.closedDate ? moment.utc(typedRow.closedDate).format('MMMM Do YYYY') : undefined
            },
        },
        {
            field: 'dpd',
            headerName: 'DPD',
            width: 80,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const typedRow = params.row as AdvancesHistoryEntry;
                return typedRow.status.toString()
            }
        }
    ];
    return <div style={{ height: 630, width: '100%' }}>
        <DataGrid
            initialState={{
                sorting: {
                    sortModel: [{ field: 'loanId', sort: 'desc' }]
                }
            }}
            rows={props.clientAdvancesHistory?.advancesHistoryEntries ? getArrayWithIds(props.clientAdvancesHistory.advancesHistoryEntries) : []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
        />
    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        clientAdvancesHistory: state.clientAdvances.clientAdvancesHistory,
        isLoadingTable: state.clientAdvances.isLoadingTable
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getClientAdvancesHistory
})(ClientAdvancesHistoryTable);