import { AuthData } from "./reducer";

enum AdminUserRoles {
    Admin = 'Admin',
    Agent = 'Agent',
    LightAgent = 'LightAgent',
    Viewer = 'Viewer',
}

export const hasAccessToViewDataInTheClientCard = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
        case AdminUserRoles.LightAgent:
        case AdminUserRoles.Viewer:
            return true;

        default:
            return false;
    }
}

export const hasAccessToBlockClients = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
        case AdminUserRoles.LightAgent:
            return true;

        default:
            return false;
    }
}


export const hasAccessToBlockClientsInMBanq = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
        case AdminUserRoles.LightAgent:
            return true;

        default:
            return false;
    }
}

export const hasAccessToDeleteArgyleData = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
        case AdminUserRoles.LightAgent:
            return true;

        default:
            return false;
    }
}

export const hasAccessToCloseAccount = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
            return true;

        default:
            return false;
    }
}

export const hasAccessToUpdateName = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
            return true;

        default:
            return false;
    }
}

export const hasAccessToUpdatePhoneNumber = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
            return true;

        default:
            return false;
    }
}

export const hasAccessToUpdateAddress = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
            return true;

        default:
            return false;
    }
}

export const hasAccessToUpdateEmail = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
        case AdminUserRoles.LightAgent:
            return true;

        default:
            return false;
    }
}

export const hasEditAccessToPayrollKeywords = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
            return true;

        default:
            return false;
    }
}

export const hasViewAccessToPayrollKeywords = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
        case AdminUserRoles.Agent:
        case AdminUserRoles.LightAgent:
        case AdminUserRoles.Viewer:
            return true;

        default:
            return false;
    }
}

export const hasAccessToAdminUsers = (authData: AuthData): boolean => {
    switch (authData.role) {
        case AdminUserRoles.Admin:
            return true;

        default:
            return false;
    }
}
