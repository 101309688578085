import { throwError } from '../Error/actions';
import sendAuthRequest, { decodeJwt, removeAuthDataFromSessionStorage, setAuthDataToSessionStorage } from '../Auth/authHelper';
import { AuthData } from './reducer';
import retry from 'async-retry';

// Sign In User (get access token)
export enum SIGN_IN_USER {
    REQUEST = 'SIGN_IN_USER_REQUEST',
    SUCCESS = 'SIGN_IN_USER_SUCCESS',
}

export const signInUserRequest = () => {
    return {
        type: SIGN_IN_USER.REQUEST
    };
};

type AuthTokenWithMetadata = {
    id_token: string;
    access_token: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token: string;
}

export const signInUser = (userName: string, password: string) => {
    var form_data = new FormData();

    form_data.append("grant_type", "password");
    form_data.append("username", userName);
    form_data.append("password", password);
    form_data.append("scope", "openid profile BNineAPI offline_access");
    form_data.append("client_id", "BNineAdminUI");
    form_data.append("client_secret", "39c9b86d-da05-4636-9833-55d7214c6963");

    return (dispatch: any) => {
        dispatch(signInUserRequest());

        const requestFn = async () => {
            return sendAuthRequest(`/connect/token`, 'POST', form_data, 'form-data');
        };

        retry(requestFn, { retries: 3 })
            .then((response: any) => {
                dispatch(getTokenSuccess(response, SIGN_IN_USER.SUCCESS));
            })
            .catch((error: any) => {
                const typedError = error as AuthErrorObject;

                dispatch(throwError(typedError.error_description));

                //should trigger login window
                removeAuthDataFromSessionStorage();
            });
    };
};

// Refresh token
export enum REFRESH_TOKEN {
    REQUEST = 'REFRESH_TOKEN_REQUEST',
    SUCCESS = 'REFRESH_TOKEN_SUCCESS',
}

export const refreshTokenRequest = () => {
    return {
        type: REFRESH_TOKEN.REQUEST
    };
};

interface AuthErrorObject {
    error: string;
    error_description: string;
}

export const refreshToken = (refreshToken: string) => {
    var form_data = new FormData();
    console.log("refresh token called")
    form_data.append("grant_type", "refresh_token");
    form_data.append("refresh_token", refreshToken);
    form_data.append("client_id", "BNineAdminUI");
    form_data.append("client_secret", "39c9b86d-da05-4636-9833-55d7214c6963");

    return (dispatch: any) => {
        dispatch(signInUserRequest());

        const requestFn = async () => {
            return sendAuthRequest(`/connect/token`, 'POST', form_data, 'form-data');
        };

        retry(requestFn, { retries: 3 })
            .then((response: any) => {
                dispatch(getTokenSuccess(response, REFRESH_TOKEN.SUCCESS));
            })
            .catch((error: any) => {
                const typedError = error as AuthErrorObject;

                dispatch(throwError(typedError.error_description));

                //should trigger login window
                removeAuthDataFromSessionStorage();
            });
    };
};

const getTokenSuccess = (authTokenWithMetadata: AuthTokenWithMetadata, type: REFRESH_TOKEN.SUCCESS | SIGN_IN_USER.SUCCESS) => {
    const decodedJwtToken = decodeJwt(authTokenWithMetadata.access_token);

    const authData = {
        access_token: authTokenWithMetadata.access_token,
        expires_in: authTokenWithMetadata.expires_in,
        fullName: decodedJwtToken.name,
        role: decodedJwtToken.role,
        refresh_token: authTokenWithMetadata.refresh_token
    } as AuthData;

    setAuthDataToSessionStorage(authData);

    return {
        type: type,
        authData: authData
    };
}