import { Link, Typography } from "@mui/material";
import { getTenantData } from "../../TenantMappings/actions";
import {connect} from "react-redux";
import {AppState} from "../../reducers";
import {useEffect} from "react";
import {TenantData} from "../../TenantMappings/reducer";
import { resetError } from '../../Error/actions';
import CustomAlert from "../../core/Alert/Alert";
import * as React from "react";





interface StateFromProps {
    errorMessage: string;
    isError: boolean;
    isLoading: boolean;
    tenantData?: TenantData;
}

interface DispatchFromProps {
    getTenantData: () => void;
    resetError: () => void;
}



const Copyright = (props: StateFromProps & DispatchFromProps) =>  {

    useEffect(() => {
        props.getTenantData()
    }, []);

    return <>
        <CustomAlert
            errorMessage={props.errorMessage}
            isError={props.isError}
            resetError={props.resetError}
        />
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
          {props.tenantData?.Website && (
              <Link color="inherit" href={`${props.tenantData.Website}`}>
                  {`${props.tenantData.Name}`}
              </Link>
          )}{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>;
  }

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        errorMessage: state.error.errorMessage,
        isError: state.error.isError,
        isLoading: state.tenantMappings.isLoading,
        tenantData: state.tenantMappings.tenantData
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {getTenantData, resetError})(Copyright);
