import { gql } from "@apollo/client";

export const GetTransactionLimitsClient = gql`
query Client($clientId: String!) {
  getTransactionLimits(clientId: $clientId)
    @rest(
      type: "Client"
      path: "/clients/{args.clientId}/transaction-limits"
    ) {
    userId,
    externalCardCurrentLimits {
      pull,
      push
    },
    achCurrentLimits {
      deposit,
      withdrawl
    },
    internalCardCurrentLimits {
      physicalCard {
        limits {
          operations,
          withdrawals
        },
        cardLastDigits
      },
      virtualCard {
        limits {
          operations,
          withdrawals
        },
        cardLastDigits
      }
    }
  }
}
`;