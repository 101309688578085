import {AttemptsStats, ClientDocVAttempts} from './reducer';
import sendRequest from '../../core/apiHelper';
import { throwError } from '../../Error/actions';

// get client docv attempts
export enum GET_CLIENT_DOCV_ATTEMPTS {
    REQUEST = 'GET_CLIENT_DOCV_ATTEMPTS_REQUEST',
    SUCCESS = 'GET_CLIENT_DOCV_ATTEMPTS_SUCCESS',
}
export const getClientDocVAttemptsRequest = () => {
    return {
        type: GET_CLIENT_DOCV_ATTEMPTS.REQUEST
    };
};

export const getClientDocVAttemptsSuccess = (clientDocVAttempts: ClientDocVAttempts) => {
    return {
        type: GET_CLIENT_DOCV_ATTEMPTS.SUCCESS,
        clientDocVAttempts: clientDocVAttempts
    };
};

export const getClientDocVAttempts = (clientId: string) => {
    return (dispatch: any) => {
        dispatch(getClientDocVAttemptsRequest());
        sendRequest(`/client-kyc/${clientId}/docv-attempts`)
            .then((response: any) => {
                dispatch(getClientDocVAttemptsSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};

// grant more attempts

export enum GRANT_CLIENT_DOCV_ATTEMPTS {
    REQUEST = 'GRANT_CLIENT_DOCV_ATTEMPTS_REQUEST',
    SUCCESS = 'GRANT_CLIENT_DOCV_ATTEMPTS_SUCCESS',
}
export const grantClientDocVAttemptsRequest = () => {
    return {
        type: GRANT_CLIENT_DOCV_ATTEMPTS.REQUEST
    };
};

export const grantClientDocVAttemptsSuccess = (docVStats: AttemptsStats) => {
    return {
        type: GRANT_CLIENT_DOCV_ATTEMPTS.SUCCESS,
        clientDocVAttemptsStats: docVStats
    };
};

export const grantClientDocVAttempts = (clientId?: string) => {
    return (dispatch: any) => {
        dispatch(grantClientDocVAttemptsRequest());
        sendRequest(`/client-kyc/${clientId}/docv-attempts`, 'PUT',
            {"additionalAttempts": 1})
            .then((response: any) => {
                dispatch(grantClientDocVAttemptsSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    }
};

    
// force approve document    

export enum FORCE_APPROVE_DOC {
    REQUEST = 'FORCE_APPROVE_DOC_REQUEST',
    SUCCESS = 'FORCE_APPROVE_DOC_SUCCESS',
}

export const forceApproveDocRequest = () => {
    return {
        type: FORCE_APPROVE_DOC.REQUEST
    };
};

export const forceApproveDocSuccess = () => {
    return {
        type: FORCE_APPROVE_DOC.SUCCESS
    };
};



export type ForceApproveDocInput = {
    documentScansExternalId: string;
    documentType: string;
    documentNumber: string;
    issuingState: string;
    issuingCountry: string;

}

export const forceApproveDoc = (input: ForceApproveDocInput, clientId?: string) => {
    return (dispatch: any) => {
        dispatch(forceApproveDocRequest());
        sendRequest(`/client-kyc/${clientId}/docv-attempts/force-approve`, 'POST', input)
            .then(() => {
                dispatch(forceApproveDocSuccess());
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
        };
};


