import {
  DescriptionTwoTone as DescriptionTwoToneIcon,
  Logout as LogoutIcon,
  SentimentSatisfiedTwoTone as SentimentSatisfiedTwoToneIcon,
  Bookmark as BookmarkTwoToneIcon,

} from "@mui/icons-material";
import SecurityIcon from '@mui/icons-material/Security';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { removeAuthDataFromSessionStorage } from '../Auth/authHelper';
import { hasAccessToAdminUsers, hasViewAccessToPayrollKeywords } from '../Auth/accessLevelsHelper';
import { connect } from 'react-redux';
import { getTenantData } from "../TenantMappings/actions";
import {useEffect} from "react";

const activeStyle = { backgroundColor: "hsl(210deg 79% 46% / 8%)" };
const styleFn = ({ isActive }) => (isActive ? activeStyle : undefined);

const DrawerList = (props) => {

  useEffect(() => {
    props.getTenantData()
  }, []);

  const onLogout = () => {
    //should trigger login window
    removeAuthDataFromSessionStorage();

    document.location.reload();
  }
  return (
    <>
      <List>
        <ListItem
          disablePadding
          secondaryAction={
            <IconButton edge="end" aria-label="comments" onClick={onLogout}>
              <LogoutIcon />
            </IconButton>
          }
        >
          <ListItemButton component={NavLink} to="/profile" style={styleFn}>
            <ListItemIcon>
              <img
                height="32"
                width="23"
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_TENANT}/logo.svg`}
                alt={`${props.tenantData.Name} logo`}
              />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton to="/clients" component={NavLink} style={styleFn}>
            <ListItemIcon>
              <SentimentSatisfiedTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" />
          </ListItemButton>
        </ListItem>

        {
          props.authData && hasViewAccessToPayrollKeywords(props.authData)
            ? <ListItem disablePadding>
              <ListItemButton
                to="/payroll-keywords"
                component={NavLink}
                style={styleFn}
              >
                <ListItemIcon>
                  <BookmarkTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Payroll Keywords" />
              </ListItemButton>
            </ListItem>
            : null
        }

        <ListItem disablePadding>
          <ListItemButton
            to="/legal-documents"
            component={NavLink}
            style={styleFn}
          >
            <ListItemIcon>
              <DescriptionTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Legal Documents" />
          </ListItemButton>
        </ListItem>

        {
          props.authData && hasAccessToAdminUsers(props.authData)

            ? <ListItem disablePadding>
              <ListItemButton
                to="/access-levels"
                component={NavLink}
                style={styleFn}
              >
                <ListItemIcon>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary="Access Levels" />
              </ListItemButton>
            </ListItem>

            : null
        }
      </List>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    authData: state.user.authData,
    tenantData: state.tenantMappings.tenantData
  };
};

export default connect(mapStateToProps, {getTenantData})(DrawerList);