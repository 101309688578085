import React from "react";
import { useMutation } from "@apollo/client";

import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import { useParams } from "react-router";
import { CLIENT } from "../../PageClient";
import CustomAlert from "../../../core/Alert/Alert";
import { CLOSE_CLIENT_ACCOUNT } from "./CloseAccountQueries";

interface PassedProps {
    reasons: any[];
    isOpen: boolean;
    onClose: () => void;
    clientId: string;
}

export function DialogSubmitCloseAccount(props: PassedProps) {
    const [errorMessage, setErrorMessage] = React.useState("");

    const [closeAccount, { loading }] = useMutation(CLOSE_CLIENT_ACCOUNT, {
        variables: { clientId: props.clientId },
        refetchQueries: [CLIENT],
        onCompleted: handleClose,
        onError: (error) => {
            setErrorMessage(error.message);
        },
    });

    function handleClose() {
        props.onClose();
    }

    function handleListItemClick(reason: any) {
        closeAccount({ variables: { reasonId: reason.id } as any });
    }

    return (
        <Dialog onClose={handleClose} open={props.isOpen} maxWidth="xs" fullWidth>
            <DialogTitle>Choose the close reason</DialogTitle>

            <CustomAlert
                errorMessage={errorMessage}
                isError={errorMessage !== ""}
                resetError={() => setErrorMessage("")} />

            <List>
                {props.reasons.map((r: any) => (
                    <ListItem
                        disabled={loading}
                        button
                        onClick={() => handleListItemClick(r)}
                        key={r.id}
                        sx={{ px: 3 }}
                    >
                        <ListItemText primary={r.value} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
