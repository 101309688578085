import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";
import { ACHLimits } from './Models/UserTransactionLimits';

interface PassedProps {
    ach: ACHLimits;
}

export default function WithdrawalLimits(props: PassedProps) {
    const { withdrawl } = props.ach;

    const calculateRemainingAmount = (limit: { usedAmount: number; limitAmount: number }) => {
        return limit.limitAmount - limit.usedAmount;
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="withdrawal limits table">
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="h3" gutterBottom></Typography></TableCell>
                        <TableCell align="right">Remaining Amount</TableCell>
                        <TableCell align="right">Limit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key="daily">
                        <TableCell component="th" scope="row">Daily amount</TableCell>
                        <TableCell align="right">{calculateRemainingAmount(withdrawl.dailyLimit)}</TableCell>
                        <TableCell align="right">{withdrawl.dailyLimit.limitAmount}</TableCell>
                    </TableRow>
                    <TableRow key="monthly">
                        <TableCell component="th" scope="row">Monthly amount</TableCell>
                        <TableCell align="right">{calculateRemainingAmount(withdrawl.monthlyLimit)}</TableCell>
                        <TableCell align="right">{withdrawl.monthlyLimit.limitAmount}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}