import {
  Box,
  Container,
  CssBaseline,
  Drawer as MuiDrawer,
  Toolbar,
} from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Appbar from "./Appbar";
import DrawerList from "./layout/Drawer";
import PageClient from "./PageClient";
import PageClientToolbar from "./PageClient/PageClientToolbar";
import PageClients from "./PageClients";
import ProfilePage from "./PageProfile/ProfilePage";
import PageKeywords from "./PageKeywords";
import LegalDocumentsPage from "./PageLegalDocuments/LegalDocumentsPage";
import AdminUsersPage from './AdminUsers/AdminUsersPage';

export const DRAWER_WIDTH = 240;

function PageToolbar({ title } : any) {
  return title;
}

export default function App(props: any) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Appbar {...{ setMobileOpen, mobileOpen }}>
        <Container sx={{ mx: 0 }}>
          <Routes>
            <Route path="/" element={<PageToolbar title="Profile" />} />
            <Route path="clients" element={<PageToolbar title="Clients" />} />
            <Route path="clients/:clientId" element={<PageClientToolbar />} />
            <Route
              path="legal-documents"
              element={<PageToolbar title="Legal Documents" />}
            />
            <Route path="profile" element={<PageToolbar title="Profile" />} />
            <Route path="payroll-keywords" element={<PageToolbar title="Payroll Keywords" />} />
            <Route path="access-levels" element={<PageToolbar title="Access Levels" />}/>
          </Routes>
        </Container>
      </Appbar>
      <Box
        component="nav"
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <MuiDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
        >
          <DrawerList />
        </MuiDrawer>
        <MuiDrawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
          open
        >
          <DrawerList />
        </MuiDrawer>
      </Box>
      <Container component="main" sx={{ mx: 0 }}>
        <Toolbar sx={{ mb: 3 }} /> {/*  Height compensation */}
        <Routes>
          <Route path="/" element={<ProfilePage />} />
          <Route path="clients" element={<PageClients />} />
          <Route path="clients/:clientId" element={<PageClient />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="legal-documents" element={<LegalDocumentsPage />} />
          <Route path="payroll-keywords" element={<PageKeywords />} />
          <Route path="access-levels" element={<AdminUsersPage />} />
          
          
        </Routes>
      </Container>
    </Box>
  );
}
