import {
  AuthenticationProvider,
  oidcLog,
  OidcSecure,
} from "@axa-fr/react-oidc-context";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import WrapperApollo from "./core/WrapperApollo";
import WrapperIntl from "./core/WrapperIntl/WrapperIntl";
import reportWebVitals from "./reportWebVitals";
import messages from "./locales/en";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import thunk from "redux-thunk";
import AuthProvider from './Auth/AuthProvider';

// const themeSetter = (outerTheme) =>
//   createTheme(outerTheme, {
//     components: {
//       MuiLink: {
//         defaultProps: {
//           // component: LinkBehavior,
//         },
//       },
//       MuiButtonBase: {
//         defaultProps: {
//           // LinkComponent: LinkBehavior,
//         },
//       },
//     },
//   });

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "MS Sans Serif", "Geneva", "sans-serif"].join(","),
    h2: {
      fontSize: 18,
      fontWeight: 500,
      color: "hsl(212deg 49% 15% / 50%)",
    },
    h3: {
      fontSize: 17,
      fontWeight: 400,
      color: "hsl(212deg 49% 15% / 60%)",
    },
    dt: {
      fontWeight: 300,
      display: "inline",
      marginRight: 2,
      color: "rgba(0, 0, 0, 0.6)",
    },
    dd: {
      display: "inline",
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "html, body, #root": {
          height: "100%",
        },
      },
    },
  },
});

const store = createStore(rootReducer,
  compose(
		applyMiddleware(thunk)
	));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <WrapperIntl locale="en" messages={messages}>
       
          
            <WrapperApollo>
                <Router>
                  <App />
                </Router>
            </WrapperApollo>
        </WrapperIntl>
            
      </ThemeProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
