import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { CLIENT } from "../PageClient";

const UNBLOCK_CLIENT = gql`
  mutation Block($clientId: ID!) {
    blockClient(clientId: $clientId, input: {})
      @rest(
        type: "Client"
        method: "POST"
        path: "/clients/{args.clientId}/saving-account/unblock"
      ) {
      NoResponse
    }
  }
`;

Unblock.propTypes = {
  clientId: PropTypes.string.isRequired,
};
export default function Unblock({ clientId }) {
  const [unblock, { loading }] = useMutation(UNBLOCK_CLIENT, {
    variables: { clientId },
    refetchQueries: [CLIENT],
  });

  return (
    <LoadingButton
      loading={loading}
      onClick={unblock}
      color="warning"
      variant="contained"
    >
      Unblock Mbanq
    </LoadingButton>
  );
}
