import { THROW_ERROR } from '../../Error/actions';
import { updateObject } from '../../helpers/updateObject';
import { GET_CLIENT_CASHBACK_HISTORY } from './actions';

export type ClientCashbackHistory = {
     cashbackInfo: ClientCashbackInfo[];
}

export type ClientCashbackInfo = {
    periodStart: Date;
    transactionAmount: number;
    cashbackAmount: number;
    cashbackDate: string;
}

export type ClientCashbackState = {
    clientCashbackHistory?: ClientCashbackHistory;
    isLoadingTable: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

const initialState = {
    clientCashbackHistory: undefined,
    isLoadingTable: false,
    isLoading: false,
    isSaved: false
}

export const reducer = (state: ClientCashbackState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false });
        case GET_CLIENT_CASHBACK_HISTORY.REQUEST:
            return updateObject(state, { clientCashbackHistory: undefined, isLoadingTable: true });
        case GET_CLIENT_CASHBACK_HISTORY.SUCCESS:
            return updateObject(state, { clientCashbackHistory: action.clientCashbackHistory, isLoadingTable: false });

        default:
            return state;
    }
}