import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import { ExternalCardHistoryItem } from "../reducer";


interface PassedProps {
    item?: ExternalCardHistoryItem;
    isOpen: boolean;
    handleClose: () => void;
}

const ExternalCardActionDetailsPopup = (props: PassedProps) => {

    if (!props.item) {
        return null;
    }

    return <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        maxWidth={'xl'}
    >
        <DialogTitle>External card action details</DialogTitle>

        <DialogContent>
            <>
                <p>Request:</p>
                <pre>{JSON.stringify(JSON.parse(props.item?.request ? props.item?.request : ""), null, 2)}</pre>
                <hr/>

                <p>Response:</p>
                <pre>{JSON.stringify(JSON.parse(props.item?.response ? props.item?.response : ""), null, 2)}</pre>
                <hr/>

                <p>Duplicate Cards:</p>
                <span>
                    {props.item?.duplicateCards.map(item => `"${item} "`)}
                </span>
                <hr/>
                
                <p>Message:</p>
                <span>{props.item.message}</span>
                <hr/>

                <p>Id:</p>
                <span>{props.item.id}</span>
                <hr/>

                <p>Create At:</p>
                {moment.utc(props.item.createAt).local().format('MMMM Do YYYY, h:mm:ss a')}
            </>
        </DialogContent>

        <DialogActions>
            <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default ExternalCardActionDetailsPopup;