import { useLazyQuery } from "@apollo/client";
import { Box, Stack, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import gql from "graphql-tag";
import qs from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
//import AsideStatusFilters from "./AsideStatusFilters";

function qsParse(query, options) {
  return qs.parse(query, { arrayFormat: "bracket", ...options });
}

function qsStringify(query, options) {
  return "?" + qs.stringify(query, { arrayFormat: "bracket", ...options });
}

const CLIENTS = gql`
  query Clients(
    $search: String
    $pageSize: String
    $pageIndex: String
    $statuses: Array
  ) {
    clients(
      search: $search
      pageSize: $pageSize
      pageIndex: $pageIndex
      statuses: $statuses
    ) @rest(type: "Client", path: "/clients?{args}") {
      items
      totalCount
      totalPages
      pageNumber
    }
  }
`;

export default function TableClients() {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  const [clients, setClients] = useState({
    items: [],
    pageNumber: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const [fetchClients, { loading, error }] = useLazyQuery(CLIENTS, {
    onCompleted: (data) => {
      setClients(data.clients);
    },
  });

  const [columns] = useState([
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
      width: 300,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      width: 160,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      valueFormatter: ({ value }) =>
        intl.formatMessage({ id: `client.onboarding.status.${value}` }),
    },
    {
      field: "documentNumber",
      headerName: "Document Number",
      width: 200,
      hide: true
  
    },
    {
      field: "identityNumber",
      headerName: "SSN Number",
      width: 200,
      hide: true
  
    },
    {
      field: "id",
      headerName: "Id",
      width: 360
    },
  ]);

  const { search, statuses, pageSize, pageIndex } = useMemo(() => {
    const query = qsParse(location.search);

    return {
      search: query.search || "",
      statuses: query.statuses || [],
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    };
  }, [location.search]);

  // FIXME: для обновления при навигации назад
  useEffect(() => {
    fetchClients({
      variables: {
        search,
        pageSize,
        pageIndex,
        statuses,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchClients({
      variables: {
        search,
        pageSize,
        pageIndex,
        statuses,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, statuses, pageSize, pageIndex]);

  const debounced = useDebouncedCallback((value) => {
    updateSearchLocation({ search: value || undefined, pageIndex: undefined });
  }, 500);

  function updateSearchLocation(params) {
    const parsedQuery = qsParse(location.search);

    const query = {
      ...parsedQuery,
      ...params,
    };

    navigate(qsStringify(query));
  }
  
  function handleValueChange(event) {
    debounced(event.target.value);
  }

  function handleRowClick({ id }) {
    navigate(id);
  }

  function handlePageChange(page) {
    updateSearchLocation({ pageIndex: page + 1 });
  }
  function handlePageSizeChange(pageSize) {
    updateSearchLocation({ pageSize });
  }
  function handleStatusChange(status) {
    const [statusName, statusValue] = Object.entries(status)[0];

    if (statusValue) {
      updateSearchLocation({
        statuses: [...statuses, statusName],
        pageIndex: undefined,
      });
    } else {
      updateSearchLocation({
        statuses: statuses.filter((s) => s !== statusName),
      });
    }
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Stack direction="row" spacing={3} width={1400}>
        <Stack spacing={3} sx={{ flex: 1 }}>
          <TextField
            fullWidth
            onChange={handleValueChange}
            defaultValue={search}
            placeholder="Search..."
          />
          <DataGrid
            rows={clients.items}
            columns={columns}
            onRowDoubleClick={handleRowClick}
            autoHeight
            pageSize={clients.items.length}
            rowCount={clients.totalCount}
            page={clients.pageNumber - 1}
            pagination
            rowsPerPageOptions={[10, 30]}
            paginationMode="server"
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            error={error}
          />
        </Stack>
        {/* <AsideStatusFilters onChange={handleStatusChange} statuses={statuses} /> */}
      </Stack>
    </Box>
  );
}
