import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ClientDocument } from './reducer';
import { useEffect, useState } from 'react';
import sendRequest from '../core/apiHelper';

interface PassedProps {
    isOpen: boolean;
    handleClose: () => void;
    document: ClientDocument;
}

const ClientDocumentPreviewPopup = (props: PassedProps) => {

    useEffect(() => {
        refreshDocumentData();
    }, [props.document]);

    //base64 string file
    const [documentFileString, setDocumentFileString] = useState<string | undefined>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Request to get image file should be send from component and not from redux thunk to avoid performance issues
    const refreshDocumentData = () => {
        setIsLoading(true)
        sendRequest(`/client-document-files`, 'POST', 
            { 
                fileName: props.document.fileName, 
                documentUiType: props.document.documentUiType
            }
        )
            .then((response: any) => {
                setDocumentFileString(response);
                setIsLoading(false)
            })
            .catch((error: any) => {
                alert(`Error has been occured during download document, error: ${error}`);
                setIsLoading(false)
            });
    }

    const loadingView = <Box sx={{ display: 'flex' }}>
        <CircularProgress />
    </Box>;

    return <>
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            maxWidth={'xl'}
        >
            <DialogTitle>{props.document.fileName}</DialogTitle>

            <DialogContent>

                {
                    isLoading ? loadingView :
                        <img
                            src={`data:image/jpeg;base64,${documentFileString}`}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%'
                                //TODO: Fix photo resizing
                            }}
                        />
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>


    </>;
}

export default ClientDocumentPreviewPopup;
