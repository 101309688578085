export const THROW_ERROR = 'THROW_ERROR';

export const throwError = (errorMessage: string) => {

    return (dispatch: any) => {

        //hide exception badge after 10 sec
        setTimeout(() => dispatch(resetError()), 10000);

        dispatch({
            type: THROW_ERROR,
            errorMessage
        });
    }
};

export const RESET_ERROR = 'RESET_ERROR';

export const resetError = () => {
    return (dispatch: any) => {
        dispatch({
            type: RESET_ERROR
        });
    }
};