import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { DataGrid, GridCellValue, GridColDef, GridRenderCellParams, GridSortCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { UserUpdate } from '../reducer';
import { getUserUpdates } from '../actions';
import moment from 'moment';

interface StateFromProps {
    userUpdates?: UserUpdate[];
    isLoadingTable: boolean;
}

interface DispatchFromProps {
    getUserUpdates: (clientId: string) => void;
}

const UserUpdatesTable = (props: StateFromProps & DispatchFromProps) => {

    const { clientId } = useParams();

    useEffect(() => {
        if (clientId) {
            props.getUserUpdates(clientId);
        }
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'updateAt', headerName: 'Update At', width: 250,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as UserUpdate;

                return moment.utc(typedRow.updateAt).local().format('MMMM Do YYYY, h:mm:ss a')
            }
        },
        {
            field: 'type', headerName: 'Type', width: 220
        },
        { field: 'updateBy', headerName: 'Update By', width: 150 },
        { field: 'notes', headerName: 'Notes', width: 150 },
        {
            field: 'updateFrom', headerName: 'Update From', width: 500
        },
        {
            field: 'updateTo', headerName: 'Update To', width: 500
        },
    ];

    return <div style={{ height: 630, width: '100%' }}>
        <DataGrid
            rows={props.userUpdates ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
        />
    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        userUpdates: state.activityLog.userUpdates,
        isLoadingTable: state.activityLog.isLoadingTable
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getUserUpdates
})(UserUpdatesTable);