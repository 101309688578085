import { combineReducers } from 'redux';

import { reducer as legalDocumentReducer, LegalDocumentState } from './PageLegalDocuments/reducer';
import { reducer as errorReducer, ErrorState } from './Error/reducer';
import { reducer as userReducer, UserState } from './Auth/reducer';
import { reducer as adminUserReducer, AdminUserState } from './AdminUsers/reducer';
import { reducer as profileReducer, ProfileState } from './PageProfile/reducer';
import { reducer as clientDocumentReducer, ClientDocumentState } from './ClientDocuments/reducer';
import { reducer as activityLogReducer, ActivityLogState } from './PageClient/ActivityLog/reducer';
import { reducer as clientReducer, ClientState } from './PageClient/reducer';
import { reducer as clientCashbackReducer, ClientCashbackState } from './PageClient/Cashback/reducer';
import { reducer as tenantMappingsReducer, TenantMappingState} from './TenantMappings/reducer'
import { reducer as clientAdvancesReducer, ClientAdvancesState} from './PageClient/Advances/reducer'
import { reducer as clientDocVAttemptsReducer, ClientDocVAttemptsState} from "./PageClient/DocVerification/reducer";



export type Dictionary = {
    Id: number;
    Name: string;
};

export type PaginatedList <Type> = {
    items: Type[];
    pageNumber: number;
    totalPages: number;
    totalCount: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface AppState {
    legalDocument: LegalDocumentState;
    error: ErrorState;
    user: UserState;
    adminUser: AdminUserState,
    profile: ProfileState,
    clientDocument: ClientDocumentState
    activityLog: ActivityLogState,
    client: ClientState,
    clientCashback: ClientCashbackState,
    tenantMappings: TenantMappingState,
    clientAdvances: ClientAdvancesState,
    clientDocVAttempts: ClientDocVAttemptsState
}

const rootReducer = combineReducers<AppState>({
    legalDocument: legalDocumentReducer,
    error: errorReducer,
    user: userReducer,
    adminUser: adminUserReducer,
    profile: profileReducer,
    clientDocument: clientDocumentReducer,
    activityLog: activityLogReducer,
    client: clientReducer,
    clientCashback: clientCashbackReducer,
    tenantMappings: tenantMappingsReducer,
    clientAdvances: clientAdvancesReducer,
    clientDocVAttempts: clientDocVAttemptsReducer
});

export default rootReducer;