import React from "react";
// import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
// import es from "../../locales/es";
// import en from "../../locales/en";

// WrapperIntl.propTypes = {};
export default function WrapperIntl(props) {
  return (
    <IntlProvider
      defaultLocale="en_us"
      locale={props.locale}
      messages={props.messages}
    >
      {props.children}
    </IntlProvider>
  );
}
