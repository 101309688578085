import { THROW_ERROR } from '../Error/actions';
import { updateObject } from '../helpers/updateObject';
import {GET_LEGAL_DOCUMENTS, UPLOAD_LEGAL_DOCUMENT} from './actions';

export type LegalDocument = {
    Id: string;
    key: string;
    title: string;
    url: string;
    staticUrl: string;
}

export type LegalDocumentState = {
    legalDocuments: LegalDocument[];
    isLoadingTable: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

const initialState = {
    legalDocuments: [],
    isLoadingTable: false,
    isLoading: false,
    isSaved: false
}

export const reducer = (state: LegalDocumentState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false });
        case GET_LEGAL_DOCUMENTS.REQUEST:
            return updateObject(state, { legalDocuments: undefined, isLoadingTable: true });
        case GET_LEGAL_DOCUMENTS.SUCCESS:
            return updateObject(state, { legalDocuments: action.legalDocuments, isLoadingTable: false });
        case UPLOAD_LEGAL_DOCUMENT.REQUEST:
            return updateObject(state, {  isLoading: true });
        case UPLOAD_LEGAL_DOCUMENT.SUCCESS:
            return updateObject(state, { isLoading: false });
        default:
            return state;
    }
}