import { AdminUser } from './reducer';
import sendRequest from '../core/apiHelper';
import { throwError } from '../Error/actions';

// get admin users
export enum GET_ADMIN_USERS {
    REQUEST = 'GET_ADMIN_USERS_REQUEST',
    SUCCESS = 'GET_ADMIN_USERS_SUCCESS',
}
export const getAdminUsersRequest = () => {
    return {
        type: GET_ADMIN_USERS.REQUEST
    };
};

export const getAdminUsersSuccess = (adminUsers: AdminUser[]) => {
    return {
        type: GET_ADMIN_USERS.SUCCESS,
        adminUsers: adminUsers
    };
};

export const getAdminUsers = () => {
    return (dispatch: any) => {
        dispatch(getAdminUsersRequest());
        sendRequest(`/admin-user`)
            .then((response: any) => {
                dispatch(getAdminUsersSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};
