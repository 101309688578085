import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { DataGrid, GridCellValue, GridColDef, GridRenderCellParams, GridSortCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ExternalCardHistoryItem, UserUpdate } from '../reducer';
import { getExternalCardsHistory, getUserUpdates } from '../actions';
import moment from 'moment';
import ExternalCardActionDetailsPopup from './ExternalCardActionDetailsPopup';

interface StateFromProps {
    externalCardsHistory?: ExternalCardHistoryItem[];
    isLoadingTable: boolean;
}

interface DispatchFromProps {
    getExternalCardsHistory: (clientId: string) => void;
}

const ExternalCardsHistoryTable = (props: StateFromProps & DispatchFromProps) => {

    const { clientId } = useParams();
    
    useEffect(() => {
        if (clientId) {
            props.getExternalCardsHistory(clientId);
        }
    }, []);


    const [selectedEvent, setSelectedEvent] = useState<ExternalCardHistoryItem | undefined>(undefined);

    const columns: GridColDef[] = [
        {
            field: 'createAt', headerName: 'Create At', width: 250,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ExternalCardHistoryItem;

                return moment.utc(typedRow.createAt).local().format('MMMM Do YYYY, h:mm:ss a')
            }
        },
        { field: 'action', headerName: 'Action', width: 200 },
        {
            field: 'message', headerName: 'Message', width: 650
        },
        {
            field: 'id', headerName: '', width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ExternalCardHistoryItem;

                return <Button onClick={() => setSelectedEvent(typedRow)}>See details</Button>;
            }
        },
    ];

    return <div style={{ height: 630, width: '100%' }}>
        <DataGrid
            rows={props.externalCardsHistory ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
        />

        <ExternalCardActionDetailsPopup 
            item={selectedEvent} 
            isOpen={selectedEvent !== undefined}
            handleClose={() => setSelectedEvent(undefined)}
        />
    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        externalCardsHistory: state.activityLog.externalCardsHistory,
        isLoadingTable: state.activityLog.isLoadingTable
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getExternalCardsHistory
})(ExternalCardsHistoryTable);