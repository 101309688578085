export const getArrayWithIds = (inputArray: any[]) => {
    let id = 0;
    const arrayCopy = inputArray.map(input => {
        const item = {
            ...input,
            id: id
        };

        id++;

        return item;
    });

    return arrayCopy;
}