import { Alert } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import CustomAlert from '../core/Alert/Alert';
import { AppState } from '../reducers';
import AdminUsersTable from './AdminUsersTable';

interface StateFromProps {
    errorMessage: string;
    isError: boolean;
}

const AdminUsersPage = (props: StateFromProps) => {
    return <>
        <AdminUsersTable />
    </>;
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        errorMessage: state.error.errorMessage,
        isError: state.error.isError
    };
};

export default connect<StateFromProps, any, any, AppState>(mapStateToProps, {})(AdminUsersPage);
