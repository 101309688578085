import * as React from 'react';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import GenerateAccountStatementComponent from './GenerateAccountStatement/GenerateAccountStatementComponent';


interface PassedProps {

}

const OtherActionsTab = (props: PassedProps) => {

    return <>
        <div>
            <GenerateAccountStatementComponent />
        </div>
    </>
}


export default OtherActionsTab;