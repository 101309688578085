import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { getClientDocuments } from './actions';
import { ClientDocument } from './reducer';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import IconButton from '@mui/material/IconButton';
import ClientDocumentPreviewPopup from './ClientDocumentPreviewPopup';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

interface StateFromProps {
    clientDocuments?: ClientDocument[];
    isLoadingTable: boolean;
    isSaved: boolean;
}

interface DispatchFromProps {
    getClientDocuments: (clientId: string) => void;
}

const ClientDocumentsTable = (props: StateFromProps & DispatchFromProps) => {

    const { clientId } = useParams();

    useEffect(() => {
        if (clientId) {
            props.getClientDocuments(clientId);
        }
    }, []);

    const [currentImageOnPreview, setCurrentImageOnPreview] = useState<ClientDocument | undefined>(undefined);

    const columns: GridColDef[] = [
        { field: 'documentTypeKey', headerName: 'Type Key', width: 130 },
        { field: 'documentSide', headerName: 'Document Side', width: 140 },
        {
            field: 'createdOn', headerName: 'Created On', width: 250,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocument;

                return moment(typedRow.createdOn).format('MMMM Do YYYY, h:mm:ss a')
            }
        },
        {
            field: 'fileName', headerName: 'File', width: 50,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocument;

                return <IconButton onClick={() => setCurrentImageOnPreview(typedRow)}>
                    <ImageIcon />
                </IconButton>
            }
        },
        {
            field: 'id', headerName: 'File Name', width: 350,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocument;

                return <Tooltip title={typedRow.fileName}>
                        <p>{typedRow.fileName}</p>
                </Tooltip>
            }
        },



    ];

    return <div style={{ height: 630, width: '100%' }}>
        <DataGrid
            rows={props.clientDocuments ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
            components={{
                LoadingOverlay: LinearProgress,
              }}
            style={{ width: 1000 }}
            onRowDoubleClick={(params: any) => setCurrentImageOnPreview(params.row as ClientDocument)}
        />

        {
            currentImageOnPreview &&
            <ClientDocumentPreviewPopup
                isOpen={currentImageOnPreview !== undefined}
                handleClose={() => setCurrentImageOnPreview(undefined)}
                document={currentImageOnPreview}
            />
        }

    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        clientDocuments: state.clientDocument.clientDocuments,
        isLoadingTable: state.clientDocument.isLoadingTable,
        isSaved: state.legalDocument.isSaved
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getClientDocuments
})(ClientDocumentsTable);