import { THROW_ERROR } from '../../Error/actions';
import { updateObject } from '../../helpers/updateObject';
import {FORCE_APPROVE_DOC, GET_CLIENT_DOCV_ATTEMPTS, GRANT_CLIENT_DOCV_ATTEMPTS} from './actions';

export type ClientDocVAttempts = {
    attempts: ClientDocVAttempt[];
    stats: AttemptsStats
}

export type AttemptsStats = {
    maxAllowed: number;
    used: number;
    hasMore: boolean;
    clientStatus: string;
    clientStatusDetailed: ClientStatusDetailedType;
}

export enum ClientStatusDetailedType {
    Default = "default",
    ActiveUnverified = "activeUnverified",
    ActiveRestricted = "activeRestricted",
    Active = "active",
}

export enum DocVStatusEnum {
    Rejected = "reject",
    Resubmit = "resubmit",
    Approved = "accept",
}

export type ClientDocVAttempt = {
    transactionId: string;
    documentExternalId: string;
    decision: DocVStatusEnum; // replace with the actual enum values in TypeScript
    docVDocument: ClientDocVDocument;
    createdAt: Date;
}

export type ClientDocVDocument = {
    type: string | undefined;
    number: string | undefined;
    state: string | undefined;
    expirationDate: Date;
}

export type ClientDocVAttemptsState = {
    clientDocVAttempts?: ClientDocVAttempt[];
    clientDocVAttemptsStats?: AttemptsStats;
    isLoadingTable: boolean;
    isLoading: boolean;
}



const initialState = {
    clientDocVAttempts: undefined,
    clientDocVAttemptsStats: undefined,
    isLoadingTable: false,
    isLoading: false,
}

export const reducer = (state: ClientDocVAttemptsState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, 
                { 
                isLoadingTable: false, 
                isLoading: false
                });
        case GET_CLIENT_DOCV_ATTEMPTS.REQUEST:
            return updateObject(state, 
                { 
                clientDocVAttempts: undefined, 
                clientDocVAttemptsStats: undefined,
                isLoadingTable: true 
                });
        case GET_CLIENT_DOCV_ATTEMPTS.SUCCESS:
            return updateObject(state, 
                { 
                clientDocVAttempts: action.clientDocVAttempts.attempts,
                clientDocVAttemptsStats: action.clientDocVAttempts.stats,
                isLoadingTable: false
            });
        case GRANT_CLIENT_DOCV_ATTEMPTS.REQUEST:
            return updateObject(state, { 
                isLoading: true,
                clientDocVAttemptsStats: undefined,
            });
        case GRANT_CLIENT_DOCV_ATTEMPTS.SUCCESS:
            return updateObject(state, 
                { 
                clientDocVAttemptsStats: action.clientDocVAttemptsStats,
                isLoading: false
            });
        case FORCE_APPROVE_DOC.REQUEST:
            return updateObject(state, 
                { 
                isLoading: true
                });
        case FORCE_APPROVE_DOC.SUCCESS:
            return updateObject(state, 
                { 
                isLoading: false,
                clientDocVAttemptsStats: updateObject(
                    state.clientDocVAttemptsStats,{
                        clientStatusDetailed: undefined
                    }),   
            });

        default:
            return state;
    }
}