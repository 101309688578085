import * as React from 'react';
import {useEffect, useState} from 'react';
import {AttemptsStats, ClientDocVAttempt, ClientStatusDetailedType} from "./reducer";
import {useParams} from "react-router-dom";
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import moment from "moment/moment";
import {getArrayWithIds} from "../../helpers/tableHelper";
import {AppState} from "../../reducers";
import {connect} from "react-redux";
import {forceApproveDoc, ForceApproveDocInput, getClientDocVAttempts, grantClientDocVAttempts} from "./actions";
import {Box, Link, Modal, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


interface StateFromProps {
    clientDocVAttempts?: ClientDocVAttempt[];
    clientDocVAttemptsStats?: AttemptsStats;
    isLoadingTable: boolean;
}

interface DispatchFromProps {
    getClientDocVAttempts: (clientId: string) => void;
    grantClientDocVAttempts: (clientId?: string) => void;
    forceApproveDoc: (input: ForceApproveDocInput, clientId?: string) => void;
}

const ClientDocVAttemptsTable = (props: StateFromProps & DispatchFromProps, client: any) => {

    const { clientId } = useParams();

    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState<ClientDocVAttempt | null>(null);
    
    const modalWindowStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };


    // This function will be called when the modal is submitted
    const handleModalSubmit = (documentScansExternalId: string) => {
        if (currentRow) {
            const input : ForceApproveDocInput = {
                documentScansExternalId,
                documentType: currentRow.docVDocument.type as string,
                documentNumber: currentRow.docVDocument.number as string,
                issuingState: currentRow.docVDocument.state as string,
                issuingCountry: "US"
            };

            props.forceApproveDoc(input, clientId);
        }

        setOpen(false);
    };
    
    
    useEffect(() => {
        if (clientId) {
            props.getClientDocVAttempts(clientId);
        }
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Attempt', width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocVAttempt;
                const link = "https://dashboard.socure.com/#/transactions/" + typedRow.transactionId;
                return <Link href={link} target="_blank" rel="noopener noreferrer">{params.row.id + 1}</Link>
            }
        },
        {
            field: 'decision', headerName: 'Decision', minWidth: 100,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocVAttempt;
                return typedRow.decision
            }
        },
        { 
            field: 'type', 
            valueGetter: params => params.row.docVDocument.type === undefined ? "Unknown" : params.row.docVDocument.type, 
            headerName: 'Type Key', minWidth: 130 },
        { 
            field: 'number', 
            valueGetter: params => params.row.docVDocument.number === undefined ? "Unknown" : params.row.docVDocument.number,
            headerName: 'Number', minWidth: 200 },
        { 
            field: 'state', 
            valueGetter: params => params.row.docVDocument.state === undefined ? "Unknown" : params.row.docVDocument.state,
            headerName: 'State', width: 80 },
        {
            field: 'expirationDate', valueGetter: params => params.row.docVDocument.expirationDate,
            headerName: 'Expiration Date', width: 130,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocVAttempt;
                return typedRow.docVDocument.expirationDate ? moment.utc(typedRow.docVDocument.expirationDate).local().format('MM/DD/YYYY') : undefined
            }
        },
        {
            field: 'createdAt', headerName: 'Created At', width: 160,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientDocVAttempt;
                return moment.utc(typedRow.createdAt).local().format('MM/DD/YYYY HH:mm:ss')
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
            const onClick = () => {
                    const typedRow = params.row as ClientDocVAttempt;
                    if (!typedRow.documentExternalId) {
                        // If documentScansExternalId is not present, open the modal and save the current row data
                        setOpen(true);
                        setCurrentRow(typedRow);
                    } else {
                        const input : ForceApproveDocInput = {
                            documentScansExternalId: typedRow.documentExternalId as string,
                            documentType: typedRow.docVDocument.type as string,
                            documentNumber: typedRow.docVDocument.number as string,
                            issuingState: typedRow.docVDocument.state as string,
                            issuingCountry: "US"
                        };

                        props.forceApproveDoc(input, clientId);
                    }
            };
            const clientStatusesApplicableForManualApprove =
                [ClientStatusDetailedType.ActiveRestricted, ClientStatusDetailedType.ActiveUnverified];
            return (
                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={ !clientStatusesApplicableForManualApprove.includes(props.clientDocVAttemptsStats?.clientStatusDetailed ? 
                        props.clientDocVAttemptsStats?.clientStatusDetailed : ClientStatusDetailedType.Default) }
                    onClick={onClick}>
                    Approve
                </Button>
            );
        },
    },
    ];

   
    const arrWithIds =  props.clientDocVAttempts ? getArrayWithIds(props.clientDocVAttempts) : [];
    
    return <div style={{ height: 630, width: '100%' }}>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={{ ...modalWindowStyle, height: 500, width: 400 }}>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <h2>Enter Document UUID from Socure Transaction</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleModalSubmit((e.target as any).documentScansExternalId.value);
                    }}
                >
                    <TextField
                        name="documentScansExternalId"
                        label="Document UUID"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                </form>
            </Box>
        </Modal>
        <Box display="flex" justifyContent="flex-start" alignItems="center" textAlign="left">
            <Button
                variant="contained"
                color="primary"
                disabled={props.clientDocVAttemptsStats?.hasMore ||
                    props.clientDocVAttemptsStats?.clientStatusDetailed !== ClientStatusDetailedType.ActiveRestricted}
                onClick={() => { props.grantClientDocVAttempts(clientId) }}
                style={{ marginRight: '20px' }} // Adjust spacing as needed
            >
                Grant more attempts
            </Button>
            <Typography variant="button" gutterBottom>
                {getAttemptsStatsText(props.clientDocVAttemptsStats ?
                    props.clientDocVAttemptsStats : {} as AttemptsStats)}
            </Typography>
        </Box>
        <DataGrid
            rows={arrWithIds}
            columns={columns}
            pageSize={arrWithIds.length}
            loading={props.isLoadingTable}
            initialState={{
                sorting: {
                    sortModel: [{field: 'createdAt', sort: 'asc'}]}
                }}
        />
    </div>
}


const getAttemptsStatsText = (stats: AttemptsStats) =>
    { return "Used " + stats?.used + " out of " + stats?.maxAllowed + " attempts" }




const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        clientDocVAttempts: state.clientDocVAttempts.clientDocVAttempts,
        clientDocVAttemptsStats: state.clientDocVAttempts.clientDocVAttemptsStats,
        isLoadingTable: state.clientDocVAttempts.isLoadingTable
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getClientDocVAttempts, grantClientDocVAttempts, forceApproveDoc
})(ClientDocVAttemptsTable);