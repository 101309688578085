import PropTypes from "prop-types";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions
} from "@mui/material";
import { useParams } from "react-router";

export default function DeleteArgyleData() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} color="warning" variant="contained">
        Delete Argyle data
      </Button>
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

const DELETE_ARGYLE_DATA = gql`
  mutation DeleteArgyleData($clientId: ID!, $reasonId: ID!) {
    deleteArgyleData(clientId: $clientId)
      @rest(
        method: "DELETE"
        path: "/clients/{args.clientId}/argyle-user"
      ) {
      NoResponse
    }
  }
`;

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
function ConfirmationDialog({ reasons = [], onClose, open }) {
  const { clientId } = useParams();

  const [block] = useMutation(DELETE_ARGYLE_DATA, {
    variables: { clientId },
    onCompleted: handleClose,
    onError: (error) => {
      handleClose();
      alert(error)
    },
  });

  function handleClose() {
    onClose();
    document.location.reload()
  }

  function handleListItemClick(reason) {
    block({ variables: { reasonId: reason.id } });
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Argyle data deletion</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleListItemClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
