import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from './Copyright';
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { refreshToken, signInUser } from '../actions';
import { AuthData } from '../reducer';
import { useEffect, useState } from 'react';
import CustomAlert from '../../core/Alert/Alert';
import { resetError } from '../../Error/actions';

const theme = createTheme();

interface StateFromProps {
  errorMessage: string;
  isError: boolean;
  refreshTokenTimeout?: any;
  authData?: AuthData;
}

interface DispatchFromProps {
  signInUser: (userName: string, password: string) => void;
  refreshToken: (refreshToken: string) => void;
  resetError: () => void;
}

const Login = (props: StateFromProps & DispatchFromProps) => {

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email')?.toString() as string;
    const password = data.get('password')?.toString() as string;

    if (props.refreshTokenTimeout) {
      clearTimeout(props.refreshTokenTimeout);
    }

    props.signInUser(email, password);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">



        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            <CustomAlert
              errorMessage={props.errorMessage}
              isError={props.isError}
              resetError={props.resetError}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: AppState): StateFromProps => {
  return {
    errorMessage: state.error.errorMessage,
    isError: state.error.isError,
    refreshTokenTimeout: state.user.refreshTokenTimeout,
    authData: state.user.authData,
  };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
  signInUser: signInUser,
  refreshToken: refreshToken,
  resetError: resetError
})(Login);