import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClientCashbackHistory, ClientCashbackInfo } from '../reducer';
import { getClientCashbackHistory } from '../actions';
import moment from 'moment';
import { getArrayWithIds } from '../../../helpers/tableHelper';

interface StateFromProps {
    clientCashbackHistory?: ClientCashbackHistory;
    isLoadingTable: boolean;
}

interface DispatchFromProps {
    getClientCashbackHistory: (clientId: string) => void;
}

const ClientCashbackHistoryTable = (props: StateFromProps & DispatchFromProps) => {

    const { clientId } = useParams();

    useEffect(() => {
        if (clientId) {
            props.getClientCashbackHistory(clientId);
        }
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'periodStart', headerName: 'Period Start', width: 250,
            renderCell: (params: GridRenderCellParams) => {
                const typedRow = params.row as ClientCashbackInfo;
                return moment.utc(typedRow.periodStart).local().format('MMMM Do YYYY, h:mm:ss a')
            }
        },
        {
            field: 'transactionAmount', headerName: 'Transaction Amount', width: 200
        },
        { field: 'cashbackAmount', headerName: 'Cashback Amount', width: 200 },
        { field: 'cashbackDate', headerName: 'Cashback Date', width: 250 },
    ];

    return <div style={{ height: 630, width: '100%' }}>
        <DataGrid
            rows={props.clientCashbackHistory?.cashbackInfo ? getArrayWithIds(props.clientCashbackHistory?.cashbackInfo) : []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            loading={props.isLoadingTable}
        />
    </div>
}

const mapStateToProps = (state: AppState): StateFromProps => {
    return {
        clientCashbackHistory: state.clientCashback.clientCashbackHistory,
        isLoadingTable: state.activityLog.isLoadingTable
    };
};

export default connect<StateFromProps, DispatchFromProps, any, AppState>(mapStateToProps, {
    getClientCashbackHistory
})(ClientCashbackHistoryTable);