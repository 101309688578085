import { gql } from "@apollo/client";

export const FETCH_BLOCK_CLIENT_REASONS = gql`
query FetchBlockClientReasons {
  reasons @rest(type: "BlockReason", path: "/block-client-reasons") {
    id
    value
    order
  }
}
`;

export const CLOSE_CLIENT_ACCOUNT = gql`
  mutation CloseClientAccount($clientId: ID!, $reasonId: ID!) {
    closeClientAccount(clientId: $clientId, input: { reasonId: $reasonId })
      @rest(
        type: "Client"
        method: "POST"
        path: "/clients/{args.clientId}/close-account"
      ) {
      NoResponse
    }
  }
`;