import { AdminUser } from '../AdminUsers/reducer';
import sendRequest from '../core/apiHelper';
import { throwError } from '../Error/actions';

// get user profile
export enum GET_USER_PROFILE {
    REQUEST = 'GET_USER_PROFILE_REQUEST',
    SUCCESS = 'GET_USER_PROFILE_SUCCESS',
}
export const getUserProfileRequest = () => {
    return {
        type: GET_USER_PROFILE.REQUEST
    };
};

export const getUserProfileSuccess = (userProfile: AdminUser) => {
    return {
        type: GET_USER_PROFILE.SUCCESS,
        userProfile: userProfile
    };
};

export const getUserProfile = () => {
    return (dispatch: any) => {
        dispatch(getUserProfileRequest());
        sendRequest(`/profile`)
            .then((response: any) => {
                dispatch(getUserProfileSuccess(response));
            })
            .catch((error: any) => {
                dispatch(throwError(error))
            });
    };
};


// change password
export enum CHANGE_PASSWORD {
    REQUEST = 'CHANGE_PASSWORD_REQUEST',
    SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
}
export const changePasswordRequest = () => {
    return {
        type: CHANGE_PASSWORD.REQUEST
    };
};

export const changePasswordSuccess = () => {
    return {
        type: CHANGE_PASSWORD.SUCCESS
    };
};

type ChangePasswordError = {
    errors: NewPasswordFieldError;
}
type NewPasswordFieldError = {
    newPassword: string[];
    password: string[];
}

export const changePassword = (oldPassword: string, newPassword: string, newPasswordSecondInput: string) => {

    const data = {
        oldPassword,
        newPassword,
        newPasswordSecondInput
    };

    return (dispatch: any) => {
        dispatch(changePasswordRequest());
        sendRequest(`/profile/change-password`, 'POST', data)
            .then((response: any) => {
                dispatch(changePasswordSuccess());
            })
            .catch((error: any) => {
                const typedError = error as ChangePasswordError;
                if (typedError) {
                    if (typedError.errors.password) {
                        dispatch(throwError(typedError.errors.password[0]))
                        return;
                    }
                    else if (typedError.errors.newPassword) {
                        dispatch(throwError(typedError.errors.newPassword[0]))
                        return;
                    }
                }

                dispatch(throwError("An error occurred during change password"))
            });
    };
};
