import { THROW_ERROR } from '../../Error/actions';
import { updateObject } from '../../helpers/updateObject';
import { GET_CLIENT_ADVANCES_HISTORY } from './actions';

export type ClientAdvancesHistory = {
    overdueAdvancesCount: number
    advancesHistoryEntries: AdvancesHistoryEntry[];
}

export enum AdvanceAdminStatusEnum {
    Default = 0,
    Overdue = 1,
    Closed = 2,
    ClosedWithOverdue = 3,
    Active = 4,
    WriteOff = 5
}

export type AdvancesHistoryEntry = {
    loanId: number;
    disbursedAt?: Date | undefined;
    amountUsed?: number | undefined;
    originalAmount?: number | undefined;
    boost?: number | undefined;
    remainingAmount?: number | undefined;
    dueDate?: Date | undefined;
    closedDate?: Date | undefined;
    dpd?: number | undefined;
    status: AdvanceAdminStatusEnum;
};

export type ClientAdvancesState = {
    clientAdvancesHistory?: ClientAdvancesHistory;
    isLoadingTable: boolean;
    isLoading: boolean;
    isSaved: boolean;
}

const initialState = {
    clientCashbackHistory: undefined,
    isLoadingTable: false,
    isLoading: false,
    isSaved: false
}

export const reducer = (state: ClientAdvancesState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false });
        case GET_CLIENT_ADVANCES_HISTORY.REQUEST:
            return updateObject(state, { clientAdvancesHistory: undefined, isLoadingTable: true });
        case GET_CLIENT_ADVANCES_HISTORY.SUCCESS:
            return updateObject(state, { clientAdvancesHistory: action.clientAdvancesHistory, isLoadingTable: false });
        case GET_CLIENT_ADVANCES_HISTORY.ERROR:
            return updateObject(state, { isLoadingTable: false, clientAdvancesHistory: undefined });

        default:
            return state;
    }
}