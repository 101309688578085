import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import { TransferErrorLog } from "../reducer";


interface PassedProps {
    item?: TransferErrorLog;
    isOpen: boolean;
    handleClose: () => void;
}

const TransferErrorLogDetailsPopup = (props: PassedProps) => {

    if (!props.item) {
        return null;
    }

    return <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        maxWidth={'xl'}
    >
        <DialogTitle>External card action details</DialogTitle>

        <DialogContent>
            <>
                <p>Exception body:</p>
                <pre>{JSON.stringify(JSON.parse(props.item?.exceptionBody !== null ? props.item?.exceptionBody : "{}"), null, 2)}</pre>
                <hr/>

                <p>MBanq Raw Error Payload:</p>
                <pre>{JSON.stringify(JSON.parse(props.item?.mbanqRawErrorPayload ? props.item?.mbanqRawErrorPayload : "{}"), null, 2)}</pre>
                <hr/>
                
                <p>Message:</p>
                <span>{props.item.exceptionHeader}</span>
                <hr/>

                <p>Id:</p>
                <span>{props.item.id}</span>
                <hr/>

                <p>Timestamp:</p>
                {moment.utc(props.item.timestamp).local().format('MMMM Do YYYY, h:mm:ss a')}
            </>
        </DialogContent>

        <DialogActions>
            <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default TransferErrorLogDetailsPopup;