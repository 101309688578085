import { THROW_ERROR } from '../Error/actions';
import { updateObject } from '../helpers/updateObject';
import { REFRESH_TOKEN, SIGN_IN_USER } from './actions';

export type UserState = {
    authData?: AuthData;
    isAuthorized: boolean;
    refreshTokenTimeout?: any;
}

export type AuthData = {
    fullName: string;
    role: string;
    access_token: string;
    expires_in: number;
    refresh_token: string;
}

const initialState = {
    id: undefined,
    authData: undefined,
    isAuthorized: false,
    refreshTokenTimeout: undefined
}

export const reducer = (state: UserState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { isLoadingTable: false, isLoading: false });
        case SIGN_IN_USER.REQUEST:
            return updateObject(state, { authData: undefined, isAuthorized: false, refreshTokenTimeout: undefined });
        case SIGN_IN_USER.SUCCESS:
            return updateObject(state, { authData: action.authData, isAuthorized: true, refreshTokenTimeout: action.refreshTokenTimeout });
        case REFRESH_TOKEN.REQUEST:
            return updateObject(state, { authData: undefined, isAuthorized: false, refreshTokenTimeout: undefined });
        case REFRESH_TOKEN.SUCCESS:
            return updateObject(state, { authData: action.authData, isAuthorized: true, refreshTokenTimeout: action.refreshTokenTimeout });

        default:
            return state;
    }
}