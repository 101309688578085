import { updateObject } from '../helpers/updateObject';
import { RESET_ERROR, THROW_ERROR } from './actions';



export type ErrorState = {
    errorMessage: string;
    isError: boolean;
}

const initialState = {
    errorMessage: "",
    isError: false
}

export const reducer = (state: ErrorState = initialState, action: any) => {
    switch (action.type) {
        case THROW_ERROR:
            return updateObject(state, { errorMessage: action.errorMessage, isError: true });
        case RESET_ERROR:
            return updateObject(state, { errorMessage: "", isError: false });

        default:
            return state;
    }
}